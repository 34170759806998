import { $api } from '~/shared/api';

interface IPostPaymentDeposit {
    error: number;
    Msg: string;
}

/**
 * Оплата депозитом
 * @param session - ID сессии
 * @param amount - количество используемых депозитов
 * @returns 
 * @see https://doc.agb.is/develop/link_order#%D0%BE%D0%BF%D0%BB%D0%B0%D1%82%D0%B0_%D0%B4%D0%B5%D0%BF%D0%BE%D0%B7%D0%B8%D1%82%D0%BE%D0%BC
 */
export const paymentDeposit = async (session: string, amount: number) => {
    const { data } = await $api.post<IPostPaymentDeposit>('/LinkOrder/LinkOrderApi.php', {
        session,
        amount
    });
    return data;
};