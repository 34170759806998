import { forwardRef } from 'react';
import classes from './GalleryNext.module.scss';


interface IProps extends React.HTMLAttributes<HTMLDivElement> {
    activeSlide: number;
    imageWidth: number;
    gap: number;
    children: React.ReactNode|React.ReactNode[];
    imagesCount?: number;
    displayForMobile?: boolean;
}

export const List = forwardRef(({ children, activeSlide, imageWidth, gap = 0, imagesCount = 1, ...props }: IProps, ref) => (
    <div className={classes.list} {...props} ref={ref as any}>
        <div
            className={classes.list_inner}
            style={{
                transform: `translateX(-${activeSlide * ((imageWidth + (gap)) / imagesCount)}px)`,
                gap: `${gap}px`,
            }}
        >
            { children }
        </div>
    </div>
));
