import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

export default class CNotifications {
    private _readNotifications: string[] = [];

    constructor () {
        makeAutoObservable(this);
        makePersistable(this, {
            name: 'CNotifications',
            // @ts-ignore
            properties: ['_readNotifications'],
            storage: localStorage,
            expireIn: 86400000,
        });
    }

    public get unreadNotifications(): string[] {
        return this._readNotifications;
    }

    public addRreadNotification(notificationId: string): void {
        const uniqRead = new Set([...this._readNotifications, notificationId]);
        this._readNotifications = [...uniqRead];
    }

    public hasUnread(notifications: string[]): boolean {
        return notifications.some(notification => !this._readNotifications.includes(notification));
    }
}