import { useStore } from '~/shared/hooks/useStore';
import RecommendationItem from './RecommendationItem';
import classes from './Recommendations.module.scss';
import { useEffect } from 'react';
import { EYMActions } from '~/processes/providers/yandexMetrika/entity';
import { useYandexHit } from '~/processes/providers/yandexMetrika/hooks/useYandexHit';
import recommendationIcon1 from '~/assets/icons/recommendations/icon1.svg';
import recommendationIcon2 from '~/assets/icons/recommendations/icon2.svg';
import recommendationIcon3 from '~/assets/icons/recommendations/icon3.svg';
import { useRecommendationActions } from './hooks/useRecommendationActions';

const Recommendations = () => {
    const { CRecommendations } = useStore();
    const { recommendations } = CRecommendations;
    const { ymHit } = useYandexHit();
    const { setRecommendationActions } = useRecommendationActions();

    useEffect(() => {
        if (recommendations && recommendations.recommendations.length > 0) {
            recommendations.recommendations.forEach(recommendation => {
                ymHit(EYMActions.SHOW_RECOMMENDATIONS);
                setRecommendationActions(recommendation.recommendation_id);
            });
        }
    }, []);

    return (
        <ul className={ classes.list }>
            {
                recommendations?.recommendations.map(
                    (recommendation, index) => (
                        <RecommendationItem
                            key={ recommendation.id }
                            content={ recommendation.name }
                            image={ recommendationImages[index] }
                        />
                    )
                )
            }
        </ul>
    );
};

export default Recommendations;

const recommendationImages = [
    recommendationIcon1,
    recommendationIcon2,
    recommendationIcon3,
];
