import { makeAutoObservable } from 'mobx';
import { storageKey } from '~/shared/components/LanguageSelector';

export enum ELanguages {
    EN = 'en',
    UA = 'ua',
    RU = 'ru',
    DE = 'de',
}

export default class CLocalization {
    private _lang = '';
    public readonly languages = [ELanguages.RU, ELanguages.EN, ELanguages.UA, ELanguages.DE];

    constructor() {
        makeAutoObservable(this);
    }

    /**
     * Получаем язык, который был выбран клиентом, либо устновлен сервера по умолчанию
     */
    public get lang(): string {
        return localStorage.getItem(storageKey) || this._lang;
    }

    /**
     * Устанавливает язык локализации, который будет отправляться на сервер, и работать с i18
     * @param locales - язык, который мы хотим установить клиенту
     */
    public setLang(locales: string): void {
        this._lang = locales;

        localStorage.setItem(storageKey, this._lang);
    }
}