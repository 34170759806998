import IconConfirm from '@assets/images/status-confirm.svg';
import IconNew from '@assets/images/status-new.svg';
import IconProgress from '@assets/images/status-progress.svg';
import IconSend from '@assets/images/status-send.svg';
import { type IOrderServices } from '~/processes/providers/authorization/entity';

/** Статусы заказа */
export enum EServiceStatus {
    NEW = 1,
    IN_STORAGE = 2,
    IN_PROGRESS = 3,
    EXECUTED = 4,
    ISSUED = 5,
    DONE = 6
}


export interface IServiceStatusData {
    icon: string;
    title: string;
    description: string;
}

type TServiceStatusKeys = {
    [key: number]: IServiceStatusData
};

export interface IServiceWithColor extends IOrderServices {
    colored: boolean;
}

/** Хранятся данные по ID статуса заказа */

export const SERVICE_STATUS: TServiceStatusKeys = {
    [EServiceStatus.NEW]: { icon: IconNew, title: 'order.serviceStatus.new.title', description: 'order.serviceStatus.new.description' },
    [EServiceStatus.IN_STORAGE]: { icon: IconSend, title: 'order.serviceStatus.inStorage.title', description: 'order.serviceStatus.inStorage.description' },
    [EServiceStatus.IN_PROGRESS]: { icon: IconProgress, title: 'order.serviceStatus.inProgress.title', description: 'order.serviceStatus.inProgress.description' },
    [EServiceStatus.EXECUTED]: { icon: IconConfirm, title: 'order.serviceStatus.executed.title', description: 'order.serviceStatus.executed.description' },
    [EServiceStatus.ISSUED]: { icon: IconConfirm, title: 'order.serviceStatus.issued.title', description: 'order.serviceStatus.issued.description' },
    [EServiceStatus.DONE]: { icon: IconConfirm, title: 'order.serviceStatus.done.title', description: 'order.serviceStatus.done.description' },
};
