import { useEffect, useState } from 'react';
import { useStore } from '~/shared/hooks/useStore';
import type { ITechnologistComment } from '../types';
import { useIsMobile } from '~/shared/hooks/useIsMobile';

export const useNotification = () => {
    const { COrder, CNotifications } = useStore();
    const { order_services, order_info } = COrder.order;
    const [comments, setComments] = useState<ITechnologistComment[]>([]);
    const [notificatiosIds, setNotificatiosIds] = useState<string[]>([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showAll, setShowAll] = useState(false);
    const [hasUnread, setHasUnread] = useState(false);
    const isMobile = useIsMobile();

    useEffect(() => {
        const foundedComments: ITechnologistComment[] = [];

        order_services.forEach((service) => {
            if (service.technologist_comment.length) {
                foundedComments.push(...service.technologist_comment.map((comment) => ({
                    id: comment.id,
                    orderDocNum: order_info.doc_num,
                    serviceName: service.name,
                    description: comment.description,
                    dttm: comment.dttm,
                    notificationId: `${order_info.doc_num}-${comment.id}`
                }
                )));
            }
        });

        setComments(foundedComments.reverse());
    }, [order_services]);

    useEffect(() => {
        setNotificatiosIds(comments.map(comment => comment.notificationId));
    }, [comments]);

    useEffect(() => {
        if (showDropdown) {
            const forHandleCommets = showAll || isMobile ? comments : comments.slice(0, 3);
            forHandleCommets.forEach(comment => CNotifications.addRreadNotification(comment.notificationId));
        }
    }, [showDropdown, showAll, isMobile]);

    useEffect(() => {
        setHasUnread(CNotifications.hasUnread(notificatiosIds));
    }, [notificatiosIds, CNotifications.unreadNotifications]);

    /** Открывает-закрывает список комментариев */
    const onOpenCommentsHandler = (action: boolean) => {
        setShowDropdown(action);
    };

    /** Показывает-скрывает полный список комментариев */
    const onShowAllHandler = () => {
        setShowAll(!showAll);
    };

    return {
        comments,
        showDropdown,
        onOpenCommentsHandler,
        onShowAllHandler,
        hasUnread,
        isMobile,
        showAll
    };
};
