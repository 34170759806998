import type { PropsWithChildren } from 'react';
import { useReducer } from 'react';
import type { ModalPropsType } from '~/shared/entity';
import { ModalContext } from './modalContext';
import { modalReducer } from './modalReducer';
import { HIDE_MODAL, SHOW_MODAL } from './modalTypes';

type Props = PropsWithChildren;

export const ModalState = ({
    children
}: Props) => {
    const [state, dispatch] = useReducer(modalReducer, { show: false });

    const show = (options: ModalPropsType): void =>
        dispatch({
            type: SHOW_MODAL,
            payload: { ...options },
        });

    const hide = (): void =>
        dispatch({
            type: HIDE_MODAL,
        });

    return (
        <ModalContext.Provider
            value={{
                show,
                hide,
                modal: state,
            }}
        >
            {children}
        </ModalContext.Provider>
    );
};
