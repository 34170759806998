import { useYandexHit } from '~/processes/providers/yandexMetrika/hooks/useYandexHit';
import classes from './payment.module.scss';
import SuccessIcon from '@icons/SuccessIcon';
import { EYMActions } from '~/processes/providers/yandexMetrika/entity';
import { useTranslation } from 'react-i18next';

const PaymentSuccess = () => {
    const { ymHit } = useYandexHit();
    const { t } = useTranslation('components');

    const handleBackToOrder = () => {
        ymHit(EYMActions.BACK_TO_ORDER);
    };

    return (
        <div className={classes.successBox}>
            <h1 className={classes.title}>
                {t('payments.success.title')}
            </h1>
            <div className={`${classes.info} ${classes.success}`}>
                <div className={classes.successImage}>
                    <SuccessIcon />
                </div>
                <p>
                    {t('payments.success.subTitle')}
                </p>
                <a onClick={handleBackToOrder} href={`${window.location.pathname}${window.location.search}`} className="button button-fill">
                    {t('payments.success.backButton')}
                </a>
            </div>
        </div>
    );
};

export default PaymentSuccess;
