import bonusImage from '@images/bonus.jpg';
import Bonus from '@components/Bonus/Bonus';

const BonusPage = () => (
    <>
        <Bonus image={bonusImage} />
    </>
);

export default BonusPage;
