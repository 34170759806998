import type { ITechnologistComment } from '../../types';
import { type FC } from 'react';
import CommentIcon from './CommentIcon.svg';
import { truncateText } from '../../lib/truncateText';
import ArrowBottomIcon from '~/components/Icons/ArrowBottomIcon';
import { useTranslation } from 'react-i18next';
import { useComment } from '../../model/useComment';
import classes from './Comment.module.scss';

interface IProps {
    comment: ITechnologistComment;
    maxDescriptionLength?: number;
}

export const Comment: FC<IProps> = ({ comment, maxDescriptionLength: maxLengthDescription = 110  }) => {
    const { open, setOpen, isRead } =useComment(comment);
    const { t } = useTranslation();

    return (
        <li className={`${classes.comment_container} ${!isRead && classes.new}`}>
            <img src={CommentIcon} alt="comment" className={classes.image} />
            <div className={classes.comment}>
                <div className={classes.title}>
                    { t('components:technologistsComment.commentTitle') } ({ comment.serviceName })
                </div>
                <div className={classes.description}>
                    { open
                        ? comment.description
                        : truncateText(comment.description, maxLengthDescription) }
                </div>
            </div>
            { comment.description.length > maxLengthDescription ? (
                <button
                    className={`${classes.arrow} ${open ? classes.arrow_rotate : ''}`}
                    onClick={() => setOpen(!open)}
                >
                    <ArrowBottomIcon />
                </button>  
            ) : null }
        </li>
    );
};
