import { useStore } from '~/shared/hooks/useStore';
import { EOrderConfirmStatus } from '~/processes/providers/authorization';
import { EOrderStatus, ORDER_STATUS } from '../entity';
import IconNotConfirmed from '@assets/images/status-notconfirmed.svg';
import { useTranslation } from 'react-i18next';

/**
 * Хук используется для получения данных о статусе заказа
 * @param statusData.icon - возвращает ссылку на статус иконки
 * @param statusData.title - возвращает текст, который будет рядом с иконкой
 * @param statusData.description - возвращает текст, который будет отображаться в описании этого заказа
 * @returns {icon, title, description}
 */
export const useStatusData = () => {
    const { COrder } = useStore();
    const { t } = useTranslation('components');
    const { order_info } = COrder.order;
    const { waiting_confirm, is_on_delivery_point, status_id } = order_info;
    /** Получаем данные по ID статуса заказа, если не нашли, выставляем дефолтные данные */
    const statusData = Object.assign({}, ORDER_STATUS[order_info.status_id] || ORDER_STATUS[EOrderStatus.NEW]);

    /** Если заказ ожидает подтверждения, то выставляем иконку подтверждения заказа, и новые текстовки */
    if (waiting_confirm === EOrderConfirmStatus.REQUIRES_CONFIRMATION) {
        statusData.icon = IconNotConfirmed;
        statusData.title = 'order.orderStatus.common.notConfirm';
        statusData.description = 'order.orderStatus.common.waitingConfirm';
    }

    /** Если заказ был отменён, тогда выставляем иконку отмены заказа, и новые текстовки */
    if (waiting_confirm === EOrderConfirmStatus.CANCELED) {
        statusData.icon = IconNotConfirmed;
        statusData.title = 'order.orderStatus.common.notConfirm';
        statusData.description = 'order.orderStatus.common.canceled';
    }

    /** Если заказ подтверждён, или его не нужно подтвержать, что выставляем данные из объекта, и добавляем текст, если он готов к выдаче */
    if (waiting_confirm === EOrderConfirmStatus.CONFIRM || waiting_confirm === EOrderConfirmStatus.NOT_REQUIRES_CONFIRMATION) {
        const onDeliveryPoint = status_id === EOrderStatus.EXECUTED && is_on_delivery_point === 1;
        statusData.description = `${t(statusData.description)} ${onDeliveryPoint ? t('order.orderStatus.common.readyToGive') : ''}`;
    }

    return statusData;
};