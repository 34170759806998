import Section from '@components/Section/Section';
import Confirm from '@components/Confirm/Confirm';
import ButtonBack from '@components/ButtonBack/ButtonBack';

const ConfirmPage = () => (
    <Section>
        <ButtonBack />
        <Confirm />
    </Section>
);

export default ConfirmPage;
