import { useContext } from 'react';
import { buildApiString } from '~/shared/lib/helpers';
import { SliderDispatchContext } from './model/context';

interface IProps extends React.HTMLAttributes<HTMLImageElement> {
    image: string;
    width?: number;
    isActive?: boolean;
}

export const Slide = ({ image, width, isActive = false, ...props }: IProps) => {
    const dispatch = useContext(SliderDispatchContext);

    const onImageLoad = () => {
        dispatch({ type: 'ADD_LOADED_IMAGE' });
    };

    return (
        <img
            src={buildApiString(image)}
            width={width}
            style={{ border: isActive ? '2px solid #FFF' : '' }}
            onLoad={onImageLoad}
            {...props}
        />
    );
};
