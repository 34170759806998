import { useContext, useEffect, useState } from 'react';
import { SliderContext } from './context';

interface IConfig {
    imagesCount: number;
}

export const useImageLoading = ({ imagesCount }: IConfig) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const { imagesLoaded } = useContext(SliderContext);

    useEffect(() => {
        if (imagesLoaded >= imagesCount * 2) {
            setIsLoaded(true);
        }
    }, [imagesLoaded]);

    return {
        isLoaded,
    };
};
