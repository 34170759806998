import type { ReactElement } from 'react';
import { useContext, useEffect, useState } from 'react';
import Barcode from 'react-barcode';
import classes from './orderHeader.module.scss';
import { ModalContext } from '~/context/modal/modalContext';
import { useStore } from '~/shared/hooks/useStore';
import { observer } from 'mobx-react';
import { useYandexHit } from '~/processes/providers/yandexMetrika/hooks/useYandexHit';
import { EYMActions } from '~/processes/providers/yandexMetrika/entity';
import { useStatusData } from './hooks/useStatusData';
import { useTranslation } from 'react-i18next';
import BarcodeImage from '~/assets/images/barcode.svg';
import UiFilledButton from '../Ui/UiFilledButton';
import { buildApiString } from '../../shared/lib/helpers';
import { twMerge } from 'tailwind-merge';
import { EOrderConfirmStatus } from '../../processes/providers/authorization';
import LoadingCircle from '../../shared/components/loadingCircle';
import { useIsMobile } from '~/shared/hooks/useIsMobile';

interface IOrderHeaderProps {
    icon?: ReactElement;
}

const OrderHeader = ({
    icon,
}: IOrderHeaderProps) => {
    const { show } = useContext(ModalContext);
    const { COrder, CClientInfo } = useStore();
    const { order_info } = COrder.order;
    const { waiting_confirm } = order_info;
    const { clientInfo } = CClientInfo;
    const { t } = useTranslation('components');
    const { ymHit } = useYandexHit();
    const statusData = useStatusData();
    const [isReceiptLoaded, setIsReceiptLoaded] = useState(false);
    const [receiptLink, setReceiptLink] = useState('');
    const isMobile = useIsMobile();


    const modalProps = {
        title: t('order.header.modal.title'),
        content: (
            <div className={classes.barcode}>
                <Barcode
                    value={String(order_info.barcode)}
                    format="CODE128"
                    fontSize={16}
                    font="sans-serif"
                    textMargin={10}
                    width={2.5}
                />
            </div>
        ),
    };

    const showBarcode = () => {
        show(modalProps);
        ymHit(EYMActions.OPEN_BARCODE_MODAL);
    };

    const modalPropsReceipt = (href: string) => ({
        content: (
            <>
                <img style={{ alignSelf: 'start' }} src={href} onLoad={() => setIsReceiptLoaded(true)} />
                {!isReceiptLoaded && <LoadingCircle />}
            </>
        ),
        fullScreen: isMobile,
    });

    useEffect(() => {
        if (isReceiptLoaded) {
            show(modalPropsReceipt(receiptLink));
            setIsReceiptLoaded(false);
        }
    }, [isReceiptLoaded]);

    const handleToReceipt = (href: string) => {
        ymHit(EYMActions.OPEN_RECEIPT_WINDOW);

        show(modalPropsReceipt(href));
        setReceiptLink(href);
    };


    return (
        <div className={`${classes.header} ${classes.withInfo}`}>
            <h2 className={classes.title}>
                {t('order.header.title')}
                <span>
                    {order_info.doc_num}
                </span>
                <button onClick={showBarcode}>
                    <img src={BarcodeImage} alt="barcodeImage" />
                </button>
            </h2>
            {clientInfo && (
                <div className={classes.info}>
                    <div className={classes.desktop}>
                        {clientInfo.full_name && (
                            <p>
                                {t('order.header.client_info.full_name', {
                                    full_name: clientInfo.full_name
                                })}
                            </p>
                        )}
                    </div>
                </div>
            )}
            <div className={twMerge(classes.description, 'flex text-[1rem] md:text-[1.2rem] flex-col md:flex-row mb-0 md:mb-2 justify-between items-start')}>
                <div className="flex flex-row items-center justify-start">
                    {icon && icon}
                    <p className="self-start" dangerouslySetInnerHTML={{ __html: t(statusData.description.trim(), { interpolation: { escapeValue: false } },) }} />
                </div>
                {waiting_confirm !== EOrderConfirmStatus.REQUIRES_CONFIRMATION && (
                    <UiFilledButton
                        onClick={handleToReceipt}
                        href={buildApiString(`${order_info.receipt_link}&mobile=${ isMobile }`)}
                        text={t('order.total.viewReceipt')}
                        classNames='button-fill !mt-6 md:!mt-0 self-start md:self-center h-[100px]'
                    />
                )}
            </div>
        </div>
    );
};

export default observer(OrderHeader);
