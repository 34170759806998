import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { BellIcon } from '@icons/BellIcon';
import { DropdownComments } from '../DropdownComments/DropdownComments';
import { useNotification } from '../../model/useNotification';
import { Comment } from '../Comment/Comment';
import { ShowAllButton } from '../ShowAllButton/ShowAllButton';
import classes from './Notification.module.scss';

export const Notification = observer(() => {
    const {
        comments,
        hasUnread,
        showDropdown,
        isMobile,
        showAll,
        onOpenCommentsHandler,
        onShowAllHandler,
    } = useNotification();

    const { t } = useTranslation();

    return (
        <div className={classes.notification}>
            <button onClick={() => onOpenCommentsHandler(!showDropdown)}>
                <BellIcon className={classes.icon} />
                { hasUnread ? (
                    <div className={classes.circle} />
                ) : null }
            </button>
            <DropdownComments
                isOpen={showDropdown}
                onClose={() => onOpenCommentsHandler(false)}
            >
                { showAll || isMobile ? (
                    comments.map((comment, index) => (
                        <Comment
                            key={index}
                            comment={comment}
                        />
                    ))
                ) : (
                    comments.slice(0, 3).map((comment, index) => (
                        <Comment
                            key={index}
                            comment={comment}
                        />
                    ))
                )}
                { !isMobile && comments.length > 3 ? (
                    <ShowAllButton
                        onClick={onShowAllHandler}
                        label={
                            showAll
                                ? t('components:technologistsComment.hide')
                                : t('components:technologistsComment.showMore')
                        }
                    />  
                ) : null }
                { !comments.length ? (
                    <div className={classes.noComments}>
                        <span>
                            { t('components:technologistsComment.noComments') }
                        </span>
                    </div>
                ) : null }
            </DropdownComments>
        </div>
    );
});
