import IconNotConfirmed from '@assets/images/status-notconfirmed.svg';
import IconConfirm from '@assets/images/status-confirm.svg';
import IconNew from '@assets/images/status-new.svg';
import IconProgress from '@assets/images/status-progress.svg';
import IconSend from '@assets/images/status-send.svg';

/** Статусы заказа */
export enum EOrderStatus {
    NEW = 1,
    IN_STORAGE = 2,
    IN_PROGRESS = 3,
    EXECUTED = 4,
    ISSUED = 5,
    DONE = 6,
    CANCELED = 7,
}


export interface IOrderStatusData {
    icon: string;
    title: string;
    description: string;
}

type TOrderStatusKeys = {
    [key: number]: IOrderStatusData
};

/** Хранятся данные по ID статуса заказа */
export const ORDER_STATUS: TOrderStatusKeys = {
    [EOrderStatus.NEW]: { icon: IconNew, title: 'order.orderStatus.new.title', description: 'order.orderStatus.new.description' },
    [EOrderStatus.IN_STORAGE]: { icon: IconSend, title: 'order.orderStatus.inStorage.title', description: 'order.orderStatus.inStorage.description' },
    [EOrderStatus.IN_PROGRESS]: { icon: IconProgress, title: 'order.orderStatus.inProgress.title', description: 'order.orderStatus.inProgress.description' },
    [EOrderStatus.EXECUTED]: { icon: IconConfirm, title: 'order.orderStatus.executed.title', description: 'order.orderStatus.executed.description' },
    [EOrderStatus.ISSUED]: { icon: IconConfirm, title: 'order.orderStatus.issued.title', description: 'order.orderStatus.issued.description' },
    [EOrderStatus.DONE]: { icon: IconConfirm, title: 'order.orderStatus.done.title', description: 'order.orderStatus.done.description' },
    [EOrderStatus.CANCELED]: { icon: IconNotConfirmed, title: 'order.orderStatus.canceled.title', description: 'order.orderStatus.canceled.description' },
};
