import { useContext, useState } from 'react';
import { EYMActions } from '~/processes/providers/yandexMetrika/entity';
import { EApiStatus } from '~/shared/api/entity';
import { ModalContext } from '~/context/modal/modalContext';
import { useYandexHit } from '~/processes/providers/yandexMetrika/hooks/useYandexHit';
import type { IPostPaymentBonus } from '../api/paymentBonus';
import { paymentBonus } from '../api/paymentBonus';
import { useStore } from '~/shared/hooks/useStore';
import { useTranslation } from 'react-i18next';

type TQRPayment = [(e: React.FormEvent) => Promise<IPostPaymentBonus>, boolean, boolean];

export const useBonusPayment = (session: string): TQRPayment => {
    const { CAuthorization } = useStore();
    const { ymHit } = useYandexHit();
    const { show } = useContext(ModalContext);
    const { t } = useTranslation('components');
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const bonusPayment = async (e: React.FormEvent) => {
        e.preventDefault();
        ymHit(EYMActions.PAYMENT_BONUS);

        const target = e.currentTarget as typeof e.currentTarget & {
            bonus: { value: string }
        };

        setLoading(true);

        const bonusAmount = Number(target.bonus.value);

        if (target.bonus.value.length <= 0 && bonusAmount <= 0) {
            setLoading(false);
            return show({
                title: t('payments.hooks.bonus.modalTitle'),
                text: t('payments.hooks.bonus.invalidValue', { bonusAmount })
            });
        }

        try {
            const query = await paymentBonus(session, bonusAmount);

            if (query.error === EApiStatus.OK) {
                await CAuthorization.updateData();
                setLoading(false);
                target.bonus.value = '';

                return show({
                    title: t('payments.hooks.bonus.modalTitle'),
                    text: t('payments.hooks.bonus.correctValue', { bonusAmount })
                });
            }

            /**
             * Временное решение для информирования пользователя о превышенном лимите оплаты бонусами.
             * В будущем должна быть реализована валидация формы. Пока нет возможности по API заранее передать 
             * максимально допустимое количество бонусов для оплаты.
             * */
            const isExeededLimit = /Превыш/.test((query as any).message);
            const excessAmount = (query as any).message.match(/\b\d+\b/)[0];

            if (isExeededLimit && excessAmount) {
                setError(true);
                setLoading(false);
                return show({
                    title: t('payments.hooks.bonus.modalTitle'),
                    text: t('payments.hooks.bonus.invalidBonusSum', { bonusLimit: excessAmount }),
                });
            }
            /* ============== */

            show({
                title: t('payments.hooks.bonus.modalTitle'),
                text: t('payments.hooks.bonus.errorValue'),
            });
            setError(true);
            setLoading(false);
        } catch (error) {
            show({
                title: t('payments.hooks.bonus.modalTitle'),
                text: t('payments.hooks.bonus.errorValue'),
            });
            setError(true);
            setLoading(false);
        }
    };

    return [bonusPayment, loading, error];
};