import type { FormEvent } from 'react';
import { useContext, useEffect, useState } from 'react';
import classes from './feedback.module.scss';
import RatingStars from '@components/RatingStars/RatingStars';
import { sendFeedback } from './api/sendFeedback';
import { useStore } from '~/shared/hooks/useStore';
import { observer } from 'mobx-react-lite';
import { EApiStatus } from '~/shared/api/entity';
import { ModalContext } from '~/context/modal/modalContext';
import type { TCommentTarget } from './entity';
import { DEFAULT_STARS_COUNT, STORAGE_RATE_KEY } from './entity';
import { useTranslation } from 'react-i18next';

const Feedback = () => {
    const { CLinks, CCompanyInfo, COrder } = useStore();
    const { rate_order } = CLinks.links;
    const { t } = useTranslation('components');
    const [rating, setRating] = useState<number>(DEFAULT_STARS_COUNT);
    const [display, setDisplay] = useState<boolean>(true);
    const { show } = useContext(ModalContext);
    const STORAGE_KEY = `${STORAGE_RATE_KEY}|${CCompanyInfo.companyInfo.company_id}|${COrder.order.order_info.dor_id}`;

    const onSumbit = async (e: FormEvent) => {
        e.preventDefault();

        const comment = (e.target as typeof e.target & TCommentTarget).comment;

        try {
            const query = await sendFeedback(rate_order, rating, comment.value || 'Комментарий не указан');

            if (query.error === EApiStatus.OK) {
                comment.value = '';
                localStorage.setItem(STORAGE_KEY, 'true');
                setDisplay(false);
                return show({
                    title: t('feedback.modal.title'),
                    text: t('feedback.modal.correctValue')
                });
            }

            show({
                title: t('feedback.modal.title'),
                text: query.message
            });
        } catch {
            show({
                title: t('feedback.modal.title'),
                text: t('feedback.modal.errorValue')
            });
        }

        comment.value = '';
    };

    useEffect(() => {
        const isSent = localStorage.getItem(STORAGE_KEY);
        if (isSent) {
            setDisplay(false);
        }
    }, []);

    if (!display) {
        return <></>;
    }

    return (
        <form className={classes.box} onSubmit={onSumbit}>
            <h2 className={classes.title}>
                {t('feedback.title')}
            </h2>

            <RatingStars
                rating={rating}
                onChange={(value) => setRating(value)}
            />

            <div className={classes.field}>
                <label htmlFor="comment">
                    {t('feedback.makeComment')}
                </label>
                <textarea id="comment" />
            </div>

            <button type="submit" className="button button-bordered" disabled={rating < 1}>
                {t('feedback.sendButton')}
            </button>
        </form>
    );
};

export default observer(Feedback);
