

interface IStarIconProps {
    isEmpty: boolean;
}

const StarIcon = ({ isEmpty }: IStarIconProps) => (
    <>
        {!isEmpty && (
            <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M30.4207 10.77C30.3264 10.4924 30.1529 10.2484 29.9215 10.0682C29.6901 9.88803 29.411 9.77955 29.1187 9.75616L20.5672 9.07675L16.8666 0.886334C16.7488 0.622537 16.5571 0.398481 16.3147 0.241206C16.0723 0.0839307 15.7896 0.000158797 15.5006 2.25538e-07C15.2117 -0.000158346 14.9288 0.0833032 14.6863 0.240312C14.4437 0.397321 14.2518 0.621167 14.1336 0.884834L10.4331 9.07675L1.88155 9.75616C1.59423 9.77892 1.31955 9.88396 1.09038 10.0587C0.861199 10.2335 0.68723 10.4705 0.58928 10.7416C0.491329 11.0126 0.473546 11.3061 0.538057 11.587C0.602568 11.8678 0.746642 12.1242 0.953042 12.3253L7.27258 18.485L5.03757 28.1617C4.9697 28.4546 4.99145 28.7612 5.1 29.0416C5.20854 29.322 5.39886 29.5633 5.64627 29.7342C5.89368 29.9051 6.18675 29.9977 6.48745 30C6.78815 30.0022 7.0826 29.9141 7.33258 29.747L15.5001 24.3027L23.6677 29.747C23.9232 29.9166 24.2245 30.0039 24.5311 29.9972C24.8377 29.9905 25.135 29.8901 25.3828 29.7094C25.6306 29.5287 25.8172 29.2765 25.9173 28.9867C26.0175 28.6969 26.0265 28.3833 25.9432 28.0882L23.1997 18.4895L30.0037 12.3673C30.4492 11.9654 30.6127 11.3384 30.4207 10.77Z" fill="#CD9933" />
            </svg>
        )}
        {isEmpty && (
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.172 9.48212C19.3205 9.81704 19.6403 10.0439 20.0055 10.0735L28.3981 10.7529C28.4863 10.76 28.5725 10.7935 28.6458 10.8516C28.719 10.9097 28.7765 10.9904 28.8083 11.0854C28.8739 11.2843 28.8155 11.498 28.6719 11.6305C28.6717 11.6307 28.6714 11.6309 28.6712 11.6311L21.9942 17.7524C21.7163 18.0072 21.6053 18.3966 21.7071 18.7596L24.399 28.3554C24.3991 28.3557 24.3992 28.356 24.3992 28.3563C24.4272 28.4579 24.424 28.5661 24.3903 28.6654C24.3565 28.7649 24.2947 28.8484 24.2164 28.9066L24.8126 29.7094L24.2164 28.9066C24.1382 28.9646 24.0468 28.9954 23.9544 28.9975C23.8623 28.9995 23.7703 28.9731 23.6904 28.9193C23.6901 28.9191 23.6898 28.9189 23.6895 28.9187L15.6748 23.4755C15.3357 23.2451 14.8903 23.2451 14.5511 23.4755L6.53484 28.9198L6.53374 28.9205C6.4555 28.9738 6.36538 29.0007 6.27496 29C6.18453 28.9993 6.09472 28.971 6.01714 28.9164C5.93931 28.8616 5.87664 28.7822 5.84024 28.6864C5.80377 28.5904 5.79627 28.4846 5.81927 28.3835L5.81942 28.3828L8.01304 18.7061C8.08924 18.37 7.98698 18.0183 7.74244 17.7754L1.53993 11.6158L1.53986 11.6157C1.47358 11.5499 1.42533 11.4639 1.4035 11.3671C1.38164 11.2701 1.38777 11.1688 1.42072 11.0759C1.45362 10.9831 1.51109 10.9047 1.58359 10.8484C1.65589 10.7922 1.7405 10.7599 1.82703 10.7529L1.82727 10.7529L10.2205 10.0735C10.5857 10.0439 10.9054 9.81701 11.054 9.48206L14.686 1.29015L14.6872 1.2874C14.7265 1.19805 14.789 1.12504 14.8647 1.07512C14.9402 1.02533 15.0264 0.999952 15.1129 1C15.1994 1.00005 15.2855 1.02552 15.361 1.0754C15.4366 1.12541 15.4991 1.1985 15.5383 1.2879L15.5383 1.2879L15.54 1.29171L19.172 9.48212Z" stroke="#C2C2C2" strokeWidth="2" strokeLinejoin="round" />
            </svg>
        )}
    </>
);

export default StarIcon;