import { Slide } from './Slide';
import { useChangeSlide } from './model/useSlider';
import { ChangeSlideButton } from './ChangeSlideButton';
import classes from './GalleryNext.module.scss';
import { ChevronLeft, ChevronRight } from './Chevron';
import { SliderContext, SliderDispatchContext } from './model/context';
import type { IImage } from './model/types';
import { useContext, useEffect } from 'react';
import { List } from './List';
import { useTouch } from './model/useTouch';
import { useSlideWidth } from './model/useSlideWidth';
import { useImageLoading } from './model/useImageLoading';
import { ImageSkeleton } from './ImageSkeleton';

interface IProps {
    images: IImage[];
}

export const Slider = ({ images }: IProps) => {
    const { isVisibleChangeSlideButton, activeSlide } = useContext(SliderContext);
    const dispatch = useContext(SliderDispatchContext);
    const { changeSlide } = useChangeSlide({ slidesCount: images.length });
    const { handleTouchStart, handleTouchMove } = useTouch({ slidesCount: images.length });
    const { isLoaded } = useImageLoading({ imagesCount: images.length });

    const onEnter = () => {
        dispatch({ type: 'CHANGE_SLIDE_VISIBILITY', payload: true });
    };

    const onLeave = () => {
        dispatch({ type: 'CHANGE_SLIDE_VISIBILITY', payload: false });
    };

    const onClickPreview = (index: number) => {
        dispatch({ type: 'CHANGE_SLIDE', payload: index });
    };

    const { slideWidth, sliderListRef } = useSlideWidth({ maxWidth: 350 });

    useEffect(() => () => {
        dispatch({ type: 'SET_SLIDE', payload: 0 });
    }, []);

    useEffect(() => {
        const bodyElement = document.querySelector('body');

        if (bodyElement) {
            bodyElement.style.overflow = 'hidden';
            bodyElement.style.paddingRight = '12px';
        }

        return () => {
            if (bodyElement) {
                bodyElement.style.overflow = 'auto';
                bodyElement.style.paddingRight = '0px';
            }
        };
    }, []);

    return (
        <div
            className={classes.slider}
            onMouseEnter={onEnter}
            onMouseLeave={onLeave}
            onClick={(e: any) => e.stopPropagation()}
        >
            <ChangeSlideButton
                style={{ left: 0 }}
                clickHandler={() => changeSlide('prev')}
                Content={ChevronLeft}
                isVisible={isVisibleChangeSlideButton}
            />
            <List activeSlide={activeSlide} imageWidth={slideWidth} gap={10} imagesCount={1} ref={sliderListRef}>
                { images.map((image, index) => (
                    <Slide
                        key={index}
                        image={image.url}
                        width={slideWidth}
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                    />
                ))}
            </List>
            <List
                activeSlide={activeSlide}
                imageWidth={350}
                gap={10}
                imagesCount={4}
                className={`${classes.preview} ${classes.list}`}
            >
                { images.map((image, index) => (
                    <Slide
                        key={index}
                        image={image.url_miniature}
                        width={80}
                        isActive={index === activeSlide}
                        onClick={() => onClickPreview(index)}
                    />
                ))}
            </List>
            <ChangeSlideButton
                style={{ right: 0 }}
                clickHandler={() => changeSlide('next')}
                Content={ChevronRight}
                isVisible={isVisibleChangeSlideButton}
            />
            { !isLoaded && <ImageSkeleton /> }
        </div>
    );
};
