import { API_URL, isDevEnv } from '../config/env';
import { STORE_BUILDER } from '../store/_store.builder';

export const formatNumberPrice = (
    number: number,
    currencySign = ` ${STORE_BUILDER.COrder.order.order_info.currency}`,
): string => new Intl.NumberFormat('ru-RU').format(number) + currencySign;

/**
 * Создаёт строку, для получения сурса по АПИ
 * @param params 
 * @returns 
 */
export const buildApiString = (params: string) => `${isDevEnv ? `${API_URL}/api` : '/api'}/LinkOrder/LinkOrderApi.php?${params}`;


export const PHONE_COUNTRY = '+7';
export const PHONE_MASK = `${PHONE_COUNTRY} (___) ___-__-__`;
/**
 * Кастомная маска для инпута с вводом номера телефона. Получает значение, которое превращает в то, которое указано в маске
 * @param value target.value из элемент input
 * @param masked +7 (___) ___-__-__
 * @returns {string} Если пришло допустим 79969591833, вернёт +7 (996) 959 18 33
 */
export const maskPhone = (value: string, masked = PHONE_MASK) => {
    const template = masked;
    const def = maskToValue(template);
    const val = maskToValue(value);

    let i = 0;
    let newValue = template.replace(/[_\d]/g, (a) => i < val.length ? val.charAt(i++) || def.charAt(i) : a);

    i = newValue.indexOf('_');
    if (i !== -1) {
        newValue = newValue.slice(0, i);
    }

    const lastChar = newValue[newValue.length - 1];

    if (lastChar === '-' || lastChar === ' ' || lastChar === '(') {
        return value;
    }

    if (newValue[1] === '8') {
        newValue = newValue.substring(1, newValue.length);
    }

    return newValue;
};

/**
 * Переводит значение из маски, в нормальное без символов
 * @param value - текст номера телефона с маской
 * @returns возвращает нормальное значение телефона без скобочек
 */
export const maskToValue = (value: string) => value.replace(/\D/g, '');

/**
 * берёт первую часть из языка, например, en из en-US
 * @param lang локально
 * @returns если есть lang, то первую часть, иначе возвращает то, что было на вход
 */
export const langPart = (lang: string) => lang.indexOf('-') >= 0 ? lang.split('-')[0] : lang;


export const getBrowserLanguage = () => {
    const languages = [...navigator.languages];

    if (Array.isArray(languages)) {
        const splitLanguages = languages.map(language => langPart(language)); // en-US -> en
        const uniqueLanguages = Array.from(new Set(splitLanguages));

        return uniqueLanguages[0] === 'uk' ? 'ua' : uniqueLanguages[0]; // uk and ua is Ukranian in different iso
    }

    return 'ru';
};
