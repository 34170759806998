import { makeAutoObservable } from 'mobx';
import { type IRecommendations } from '~/processes/providers/authorization/entity';

export default class CRecommendations {
    private _recommendations: IRecommendations | null = null;
    private _showRecommendations = true;

    constructor() {
        makeAutoObservable(this);
    }

    get recommendations() {
        return this._recommendations;
    }

    setRecommendations(recommendations: IRecommendations) {
        this._recommendations = recommendations;
    }

    get showRecommendations() {
        return this._showRecommendations;
    }

    setShowRecommendations(showRecommendations: boolean) {
        this._showRecommendations = showRecommendations;
    }
}
