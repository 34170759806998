import { useContext, useEffect } from 'react';
import classes from './modal.module.scss';
import { ModalContext } from '~/context/modal/modalContext';
import CloseIcon from '@icons/CloseIcon';

const Modal = () => {
    const { modal, hide } = useContext(ModalContext);

    const handleKeydown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            hide();
        }
    };

    const handleClose = (e: MouseEvent) => {
        const target = e.target;
        if (target && (target as any).id === 'modal-overlay') {
            hide();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeydown);
        document.addEventListener('click', handleClose);
        return () => {
            document.removeEventListener('keydown', handleKeydown);
            document.removeEventListener('click', handleClose);
        };
    }, []);

    if (!modal.show) {
        return null;
    }

    if (modal.fullScreen) {
        return (
            <div className={classes.mobile}>
                { modal.content }
                <button className={classes.close} onClick={hide}>
                    <CloseIcon />
                </button>
            </div>
        );
    }

    return (
        <div className={classes.overlay} id='modal-overlay'>
            <div className={`${classes.content} ${modal.isSmall ? classes.isSmall : ''}`}>
                {modal.title && (
                    <div className={classes.title}>
                        {modal.title}
                    </div>
                )}
                {modal.text && (
                    <p className={classes.text}>
                        {modal.text}
                    </p>
                )}

                {modal.content}

                {modal.buttons && (
                    <div className={classes.buttons}>{modal.buttons}</div>
                )}

                <button className={classes.close} onClick={hide}>
                    <CloseIcon />
                </button>
            </div>
        </div>
    );
};

export default Modal;
