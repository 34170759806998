import { useState, useEffect, useCallback } from 'react';

/** Генерирует параметры сроки */
const getQueryParams = (): object => {
    /** Поиск строки параметров в строке */
    const urlParams = new URLSearchParams(window.location.search);
    /** Создание объекта ключ -> значение с URL параметрами */
    const params = Object.fromEntries(urlParams);

    return params;
};

/**
 * Возвращает параметры URL строки
 * @returns 
 */
export const useQueryParams = (): any => {
    const [query, setQuery] = useState<any>(getQueryParams());

    /** Создаём объект с query параметрами, и сохраняем в state */
    const buildQuery = useCallback(() => {
        const params = getQueryParams();
        setQuery(params);
    }, []);

    /** Если URL параметры меняются, что обновляем их */
    useEffect(() => {
        buildQuery();
    }, [window.location.search]);

    return query;
};