declare let cp: any;

export interface ICloudPaymentData {
    publicId: string,
    amount?: number,
    currency?: string,
    dor_id: number;
    doc_num: string | string[] | undefined;
    firm_id: string | string[] | undefined;
    language: string;
    CompanyID: number;
}

interface ICloudPaymentCallbackData {
    onSuccess: (options: any) => void;
    onFail: (reason: any, options: any) => void;
    onComplete: (paymentResult: any, options: any) => void;
}

const loadScript = (FILE_URL: string, async = true, type = 'text/javascript') => (new Promise((resolve, reject) => {
    try {
        const scriptEle = document.createElement('script');
        scriptEle.type = type;
        scriptEle.async = async;
        scriptEle.src = FILE_URL;

        scriptEle.addEventListener('load', () => {
            resolve({ status: true });
        });

        scriptEle.addEventListener('error', () => {
            reject({
                status: false,
                message: `Failed to load the script ${FILE_URL}`
            });
        });

        document.body.appendChild(scriptEle);
    } catch (error) {
        reject(error);
    }
}));

/**
 * Вызов виджета с оплатой через CloudPayment
 * @param contr_id - id пользователя
 * @param cloudData - ICloudPaymentData 
 * @param callbackData - ICloudPaymentCallbackData
 * @see https://developers.cloudpayments.ru/#ustanovka-vidzheta
 */
export const cloudPayment = (contr_id: number, {
    publicId,
    amount,
    currency,
    dor_id,
    doc_num,
    firm_id,
    CompanyID,
    language
}: ICloudPaymentData, callbackData: ICloudPaymentCallbackData) => {
    let cloudPaymentsWidget;

    const buildData = {
        publicId,
        description: `Оплата заказа №${doc_num}`,
        amount,
        currency: currency ?? 'RUB',
        accountId: contr_id,
        invoiceId: dor_id,
        skin: 'mini',
        data: {
            dor_id,
            doc_num,
            contr_id,
            CompanyID,
            firm_id,
        }
    };

    try {
        cloudPaymentsWidget = new cp.CloudPayments({ language });

        cloudPaymentsWidget.pay('charge', buildData, callbackData);
    } catch {
        console.log('CloudPayments is not defined');
        
        loadScript('https://widget.cloudpayments.ru/bundles/cloudpayments').then(() => {
            cloudPaymentsWidget = new cp.CloudPayments({ language });

            cloudPaymentsWidget.pay('charge', buildData, callbackData);
        }).catch((error: Error) => {
            throw new Error(`Can't load Cloudpayments widget script: ${error.message}`);
        });
    }
};