import { useEffect, useState } from 'react';
import { EDeliveryType, type IOrderPlans } from '~/processes/providers/authorization';
import { useStore } from '~/shared/hooks/useStore';

interface IdeliveryDate {
    date: string;
    time: string;
}

const buildDeliveryDate = (deliveryPlan: IOrderPlans): IdeliveryDate => {
    let date = '';
    let time = '';

    if (deliveryPlan) {
        const { dt, hr, mn, to_hr, to_mn } = deliveryPlan;
        date = `${dt}`;
        time = `${hr}:${String(mn).length === 1 ? `0${mn}` : mn} - ${to_hr}:${String(to_mn).length === 1 ? `0${to_mn}` : to_mn}`;
    }

    return {
        date,
        time,
    };
};

export const useDeliveryDate = () => {
    const [deliveryDate, setDeliveryDate] = useState<IdeliveryDate | undefined>();
    const { COrder } = useStore();
    const { order_plans } = COrder.order;

    useEffect(() => {
        if (order_plans.length === 0) {
            return;
        }

        const deliveryPlan = order_plans.find((plan) => plan.tp === EDeliveryType.DELIVERY);

        if (deliveryPlan) {
            setDeliveryDate(buildDeliveryDate(deliveryPlan));
        }

    }, [order_plans]);

    return {
        deliveryDate,
    };
};
