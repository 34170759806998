import { useContext, useState } from 'react';
import { EYMActions } from '~/processes/providers/yandexMetrika/entity';
import { EApiStatus } from '~/shared/api/entity';
import { ModalContext } from '~/context/modal/modalContext';
import { useYandexHit } from '~/processes/providers/yandexMetrika/hooks/useYandexHit';
import { useStore } from '~/shared/hooks/useStore';
import { paymentDeposit } from '../api/paymentDeposit';
import { useTranslation } from 'react-i18next';

type TQRPayment = [(e: React.FormEvent) => Promise<any>, boolean, boolean];

export const useDepositPayment = (session: string): TQRPayment => {
    const { CAuthorization } = useStore();
    const { ymHit } = useYandexHit();
    const { t } = useTranslation('components');
    const { show } = useContext(ModalContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const depositPayment = async (e: React.FormEvent) => {
        e.preventDefault();
        ymHit(EYMActions.PAYMENT_DEPOSIT);

        const target = e.currentTarget as typeof e.currentTarget & {
            deposit: { value: string }
        };

        setLoading(true);

        const depositAmount = Number(target.deposit.value);

        if (target.deposit.value.length <= 0 && depositAmount <= 0) {
            setLoading(false);
            return show({
                title: t('payments.hooks.deposit.modalTitle'),
                text: t('payments.invalidValue.bonus.invalidValue', { depositAmount })
            });
        }

        try {
            const query = await paymentDeposit(session, depositAmount);

            if (query.error === EApiStatus.OK) {
                await CAuthorization.updateData();
                setLoading(false);
                setError(true);

                target.deposit.value = '';

                return show({
                    title: t('payments.hooks.deposit.modalTitle'),
                    text: t('payments.hooks.deposit.correctValue', {
                        depositAmount
                    })
                });
            }

            show({
                title: t('payments.hooks.deposit.modalTitle'),
                text: query.Msg
            });
            setLoading(false);
            setError(true);
        } catch (error) {
            show({
                title: t('payments.hooks.deposit.modalTitle'),
                text: t('payments.hooks.deposit.errorValue')
            });
            setError(true);
            setLoading(false);
        }



    };

    return [depositPayment, loading, error];
};