import { observer } from 'mobx-react';
import { type ReactNode } from 'react';
import { useStore } from '~/shared/hooks/useStore';
import classes from './order.module.scss';
import { useStatusData } from './hooks/useStatusData';
import { useTranslation } from 'react-i18next';
import { useDeliveryDate } from './hooks/useDeliveryDate';

const STATUS_LENGTH_FOR_SMALL_SIZE = 9;

interface IOrderInfoProps {
    button?: ReactNode;
}

const OrderInfo = ({
    button,
}: IOrderInfoProps) => {
    const { COrder } = useStore();
    const { order_info } = COrder.order;
    const { t } = useTranslation('components');
    const { deliveryDate } = useDeliveryDate();
    const statusData = useStatusData();

    return (
        <ul className={`${classes.list} ${button ? classes.withButton : ''}`}>
            <li>
                <span className={classes.key}>
                    {t('order.info.status')}
                </span>
                <span className={`${classes.value} ${classes.status}`}>
                    <img src={statusData.icon} />
                    {statusData.title.length >= STATUS_LENGTH_FOR_SMALL_SIZE ? (
                        <small>{t(statusData.title)}</small>
                    ) : (
                        t(statusData.title)
                    )}
                </span>
            </li>
            <li>
                <span className={classes.key}>
                    {t('order.info.dateTake')}
                </span>
                <span className={classes.value}>
                    {order_info.date_in}
                </span>
            </li>
            {!!deliveryDate && (
                <li>
                    <span className={classes.key}>
                        {t('order.info.dateDelivery')}
                    </span>
                    <span className={classes.value}>
                        {deliveryDate.date} <br />
                        {deliveryDate.time}
                    </span>
                </li>
            )}
            {!deliveryDate && (
                <li>
                    <span className={classes.key}>
                        {t('order.info.dateGive')}
                    </span>
                    <span className={classes.value}>
                        {order_info.date_out}
                    </span>
                    <span className={classes.helpText}>
                        {t('order.info.dateChangeInformation')}
                    </span>
                </li>
            )}
            <li dangerouslySetInnerHTML={{
                __html: t('order.info.deliveryPoint', {
                    point: order_info.point_of_delivery_name,
                    adr: order_info.point_of_delivery_adr
                })
            }} />
            {button && (
                <li className={classes.button}>
                    {button}
                </li>
            )}
        </ul>
    );
};

export default observer(OrderInfo);
