import Feedback from '@components/Feedback/Feedback';
import Recommendations from '@components/Recommendations';
import Stocks from '@components/Stock/Stocks';
import Section from '@components/Section/Section';
import OrderHeader from '@components/Order/OrderHeader';
import OrderInfo from '@components/Order/OrderInfo';
import OrderTotal from '@components/Order/OrderTotal';
import Order from '@components/Order/Order';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { ERoutes } from '~/processes/routes';
import { EYMActions } from '~/processes/providers/yandexMetrika/entity';
import { useYandexHit } from '~/processes/providers/yandexMetrika/hooks/useYandexHit';
import { useStore } from '../../shared/hooks/useStore';
import { useTranslation } from 'react-i18next';
import titleIcon from '@assets/icons/thumb-up.svg';

const OrderNewPage = () => {
    const navigate = useNavigate();
    const { ymHit } = useYandexHit();
    const { t } = useTranslation('components');

    const { CCompanyInfo, CRecommendations } = useStore();
    const { promotions } = CCompanyInfo.companyInfo;
    const isShowPromotions = promotions.length > 0;

    const handleToAcceptOrder = () => {
        navigate(ERoutes.CONFIRM);
        ymHit(EYMActions.TO_ACCEPT_ORDER_PAGE);
    };

    const handleCloseRecommendations = () => {
        CRecommendations.setShowRecommendations(false);
        ymHit(EYMActions.HIDE_RECOMMENDATIONS);
    };

    return (
        <>
            <Section>
                <Order
                    header={<OrderHeader />}
                    info={<OrderInfo />}
                    total={<OrderTotal />}
                    link={(
                        <button className="button button-big basis-full md:basis-1/2" onClick={handleToAcceptOrder}>
                            {t('pages.orderNewPage.buttonConfirm')}
                        </button>
                    )}
                />
            </Section>

            { CRecommendations.showRecommendations
                && CRecommendations.recommendations
                && CRecommendations.recommendations?.recommendations.length > 0
                && (
                    <Section
                        title={ t('components:recommendations.title') as string }
                        icon={ titleIcon }
                        handleClose={handleCloseRecommendations}
                    >
                        <Recommendations />
                    </Section>
                )}

            {isShowPromotions && (
                <Section title={t('pages.orderNewPage.title') as string}>
                    <Stocks />
                </Section>
            )}

            <Section>
                <Feedback />
            </Section>
        </>
    );
};

export default observer(OrderNewPage);
