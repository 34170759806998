import { useState } from 'react';
import { useStore } from '~/shared/hooks/useStore';
import type { ITechnologistComment } from '../types';

export const useComment = (comment: ITechnologistComment) => {
    const [open, setOpen] = useState(false);
    const { CNotifications } = useStore();
    const isRead = CNotifications.unreadNotifications.includes(comment.notificationId);

    return {
        open,
        setOpen,
        isRead
    };
};
