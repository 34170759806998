import { useTranslation } from 'react-i18next';
import { EServiceStatus, SERVICE_STATUS, type IServiceWithColor } from './entity';
import { useStore } from '~/shared/hooks/useStore';
import { formatNumberPrice } from '~/shared/lib/helpers';
import classes from './services.module.scss';
import { useContext } from 'react';
import { GalleryContext } from '~/context/gallery/galleryContext';


interface IProps {
    service: IServiceWithColor;
    onlyName?: boolean;
}

const MobileSrvice = ({ service, onlyName = false }: IProps) => {
    const { t } = useTranslation('components');
    const { COrder } = useStore();
    const statusId = (service.status_id || EServiceStatus.NEW);
    const statusData = SERVICE_STATUS[statusId];
    const { show } = useContext(GalleryContext);

    if (onlyName) {
        return (
            <tr className={`${classes.mobile_parent_item} ${service.colored ? classes.colored : ''}`}>
                <td colSpan={2}>
                    {service.name}
                </td>
                { service.img_links && (
                    <td>
                        <button
                            className={classes.photo_button}
                            onClick={() => show(service.img_links)}
                        >
                            { t('services.showPhotoButton') }
                        </button>
                    </td>
                )}
            </tr>
        );
    }

    return (
        <>
            <tr className={`${classes.mobile_child_item} ${service.colored ? classes.colored : ''} ${service.parent_id.length ? classes.btt : classes.bt}`}>
                <td 
                    colSpan={3}
                >
                    <span style={{
                        float: 'left',
                        width: service.img_links ? '70%' : '100%',
                    }}
                    >
                        {service.name}
                    </span>
                    { service.img_links && (
                        <button
                            className={classes.photo_button}
                            style={{
                                float: 'right',
                            }}
                            onClick={() => show(service.img_links)}
                        >
                            { t('services.showPhotoButton') }
                        </button>  
                    )}
                </td>
            </tr>
            <tr className={`${classes.item} ${service.colored ? classes.colored : ''}`}>
                <td>{t('services.tableHeaders.status')}</td>
                <td colSpan={2}><span>{t(statusData.title)}</span></td>
            </tr>
            <tr className={`${classes.item} ${service.colored ? classes.colored : ''}`}>
                <td>{t('services.tableHeaders.amount')}</td>
                <td colSpan={2}><span>{service.number_of_units} { service.unitname ?? t('services.tableItems.namePiece')}</span></td>
            </tr>
            <tr className={`${classes.item} ${service.colored ? classes.colored : ''}`}>
                <td>{t('services.tableHeaders.price')} ({`${COrder.order.order_info.currency}`})</td>
                <td colSpan={2}>
                    <span>
                        {formatNumberPrice(service.price, '')}
                    </span>
                </td>
            </tr>
            <tr className={`${classes.item} ${service.colored ? classes.colored : ''}`}>
                <td>
                    {t('services.tableHeaders.discount')}
                </td>
                <td colSpan={2}>
                    <span>
                        {service.discount}%
                    </span>
                </td>
            </tr>
            <tr className={`${classes.item} ${service.colored ? classes.colored : ''}`}>
                <td>
                    {t('services.tableHeaders.orderSum')} ({`${COrder.order.order_info.currency}`})
                </td>
                <td colSpan={2}>
                    <span>
                        {formatNumberPrice(service.sum_service, '')}
                    </span>
                </td>
            </tr>
        </>
    );
};

export default MobileSrvice;
