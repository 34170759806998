import type { PropsWithChildren } from 'react';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import Header from '@components/Header/Header';
import { observer } from 'mobx-react';
import { useStore } from '~/shared/hooks/useStore';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Authorization from '~/processes/providers/authorization';
import { YMInitializer } from '@appigram/react-yandex-metrika';
import I18Provider from '~/processes/providers/i18';
import { useTranslation } from 'react-i18next';
import { getBrowserLanguage, langPart } from '~/shared/lib/helpers';
import { userStorageKey } from '../shared/components/LanguageSelector';

type IAppLayoutProps = PropsWithChildren;

const AppLayout = observer(({
    children
}: IAppLayoutProps) => {
    const { i18n, t } = useTranslation();
    const { COrder, CLinks, CCompanyInfo } = useStore();
    const { order_info } = COrder.order;
    const { need_metrika } = CCompanyInfo.companyInfo;

    const location = useLocation();

    const handleSelectLang = (lang: string) => {
        i18n.changeLanguage(langPart(lang));

        document.documentElement.lang = i18n.language;
    };

    ReactGA.initialize('G-N5JCJPY5G9', { gtagOptions: { send_page_view: false } });

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        const userLang = localStorage.getItem(userStorageKey) || '';

        if (!userLang) {
            handleSelectLang(getBrowserLanguage());
        }
    }, [location.pathname]);

    return (
        <>
            <Helmet>
                <title>{`${t('common:pageTitle')} ${order_info.doc_num || ''}`}</title>
                {CLinks.isUseCloudPayments() && (
                    <script src='https://widget.cloudpayments.ru/bundles/cloudpayments.js' />
                )}
            </Helmet>
            {need_metrika === 1 && (
                <YMInitializer
                    accounts={[92339735]}
                    options={{
                        defer: true,
                        clickmap: true,
                        trackLinks: true,
                        accurateTrackBounce: true,
                        webvisor: true,
                    }}
                />
            )}
            <div className="page">
                <I18Provider>
                    <Authorization>
                        <Header />
                        <main className="mt-[40px] sm:mt-[3vh]">
                            {children}
                        </main>
                    </Authorization>
                </I18Provider>
            </div>
        </>
    );
});

export default AppLayout;
