import ArrowLeftIcon from '@icons/ArrowLeftIcon';
import { useNavigate } from 'react-router-dom';
import { useYandexHit } from '~/processes/providers/yandexMetrika/hooks/useYandexHit';
import { EYMActions } from '~/processes/providers/yandexMetrika/entity';
import { useTranslation } from 'react-i18next';

interface IButtonBackProps {
    desktopHidden?: boolean;
    tabletHidden?: boolean;
    mobileHidden?: boolean;
    isBold?: boolean;
    isSide?: boolean;
    href?: string;
}

const ButtonBack = ({
    desktopHidden = false,
    tabletHidden = false,
    mobileHidden = false,
    isBold = false,
    isSide = false,
    href = '',
}: IButtonBackProps) => {
    const navigate = useNavigate();
    const { ymHit } = useYandexHit();
    const { t } = useTranslation('components');

    const handleBack = () => {
        if (href) {
            ymHit(EYMActions.BUTTON_BACK_PAGE);
            return navigate(href);
        }

        navigate(-1);
        ymHit(EYMActions.BUTTON_BACK_PAGE);
    };

    let classes = '';
    classes += desktopHidden ? 'desktop-hidden ' : '';
    classes += tabletHidden ? 'tablet-hidden ' : '';
    classes += mobileHidden ? 'mobile-hidden ' : '';
    classes += isSide ? 'button-back-side' : '';

    return (
        <div className={classes}>
            <button className={`button-back ${isBold ? 'isBold' : ''}`} onClick={handleBack}>
                <ArrowLeftIcon /> {t('buttonBack.buttonTitle')}
            </button>
        </div>
    );
};

export default ButtonBack;
