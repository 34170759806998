import { useState } from 'react';
import { useChangeSlide } from './useSlider';

interface IConfig {
    slidesCount: number;
}

export const useTouch = ({ slidesCount = 1 }: IConfig) => {
    const [touchPosition, setTouchPosition] = useState(null);
    const { changeSlide } = useChangeSlide({ slidesCount: slidesCount });

    const handleTouchStart = (e: any) => {
        const touch = e.touches[0].clientX;
        setTouchPosition(touch);
    };

    const handleTouchMove = (e: any) => {
        if (touchPosition === null) {
            return;
        }

        const currentPosition = e.touches[0].clientX;
        const diff = touchPosition - currentPosition;
    
        if (diff > 8) {
            changeSlide('next');
        }

        if (diff < -8) {
            changeSlide('prev');
        }

        setTouchPosition(null);
    };

    return { handleTouchStart, handleTouchMove };
};
