import CloseIcon from '~/components/Icons/CloseIcon';
import classes from './DropdownComments.module.scss';
import { useIsMobile } from '~/shared/hooks/useIsMobile';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

export const DropdownComments = ({ isOpen, children, onClose }: IProps) => {
    const isMobile = useIsMobile();

    const onFocus = () => {
        if (isMobile) {
            document.body.style.overflow = 'hidden';
        }
    };

    const onBlur = () => {
        document.body.style.overflow = 'auto';
    };

    return (
        <ul
            className={`${classes.dropdown} ${isOpen ? classes.active : ''}`}
            onFocus={onFocus}
            onBlur={onBlur}
        >
            <div className={classes.head}>
                <button onClick={onClose}>
                    <CloseIcon />
                </button>
            </div>
            { children }
        </ul>
    );
};
