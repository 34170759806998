import axios from 'axios';
import { API_URL, isDevEnv } from '../config/env';
import { langPart } from '../lib/helpers';
import { STORE_BUILDER } from '../store/_store.builder';

/**
 * Axios объект, который работает с URL из ENV
 * @info С помощью него можно выполнить HTTP запросы, используя HTTP методы (post, get и т.д.)
 */
const $api = axios.create({
    baseURL: isDevEnv ? `${API_URL}/api` : '/api'
});

/** Обработчик опций, отправляемых на сервер */
$api.interceptors.request.use(config => {
    const localization = STORE_BUILDER.CLocalization.lang;

    /** Отправляем на сервер язык, который установлен сейчас */
    if (langPart && localization) {
        config.headers['Accept-Language'] = langPart(localization);
    } else {
        config.headers['Accept-Language'] = 'default';
    }

    return config;
});

/** Обработчик опций, получаемых с сервера */
$api.interceptors.response.use(
    async (response) => {
        const CLocalization = STORE_BUILDER.CLocalization;
        const lang = response.headers['content-language'];

        /** Устанавливаем язык по умолчанию, который мы получаем с сервера, в случае если не выбран язык клиентом */
        if (lang && !CLocalization.lang) {
            STORE_BUILDER.CLocalization.setLang(langPart(lang));
        }

        return response;
    },
    async (error) => Promise.reject(error.response)
);

export {
    $api
};