import React from 'react';
import classes from './GalleryNext.module.scss';

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    clickHandler: () => void;
    isVisible?: boolean;
    Content: React.FC<React.SVGProps<SVGSVGElement>>;
}

export const ChangeSlideButton = ({ clickHandler, isVisible = false, Content, ...props }: IProps) => (
    <button
        className={classes.button}
        style={{ visibility: isVisible ? 'visible' : 'hidden' }}
        onClick={clickHandler}
        {...props}
    >
        <Content />
    </button>
);
