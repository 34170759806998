import CloseIcon from '@icons/CloseIcon';
import React, { useContext } from 'react';
import { GalleryContext } from '~/context/gallery/galleryContext';

export const CloseButton = ({ ...props }: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
    const { hide } = useContext(GalleryContext);
    return (
        <button onClick={() => hide()} {...props}>
            <CloseIcon />
        </button>
    );
};
