import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContext } from '~/context/modal/modalContext';
import { useYandexHit } from '~/processes/providers/yandexMetrika/hooks/useYandexHit';
import { paymentQR } from '../api/paymentQR';
import { EApiStatus } from '~/shared/api/entity';
import { EYMActions } from '~/processes/providers/yandexMetrika/entity';

type Method = 'card' | 'qr';

/**
 * Хук используется для оплаты через СБП с использованием QR-кода или банковской карты. 
 * Преимущество перед хуком useQRPayment - переход на страницу оплаты (эквайринг) 
 * происходит напрямую со страницы оплаты заказа без модальных окон.
 * @param session сессия
 * @param amount сумма для оплаты
 * @returns 
 */
export const useSBPPayment = (session: string, amount: number) => {
    const { ymHit } = useYandexHit();
    const { t } = useTranslation('components');
    const { show: showModal } = useContext(ModalContext);
    const [isLoadingCard, setIsLoadingCard] = useState(false);
    const [isLoadingQR, setIsLoadingQR] = useState(false);

    const SBPPay = async (method: Method) => {
        if (amount < 10) {
            return showModal({
                title: t('payments.hooks.qr.amountPaymentErrorTitle'),
                text: t('payments.hooks.qr.amountPaymentError'),
            });
        }

        method === 'card' ? setIsLoadingCard(true) : setIsLoadingQR(true);
        method === 'card' ? ymHit(EYMActions.PAYMENT_CARD) : ymHit(EYMActions.PAYMENT_QR_CODE);

        try {
            const paymentQuery = await paymentQR(session, amount * 100, method === 'qr' ? 1 : 0);

            if (paymentQuery.error !== EApiStatus.OK) {
                showModal({
                    title: t('payments.hooks.qr.modalTitle'),
                    text: paymentQuery.QR_Msg,
                });

                return;
            }

            window.location.assign(method === 'qr' ? paymentQuery.QR : paymentQuery.PaymentURL);
            setIsLoadingCard(false);
            setIsLoadingQR(false);
        } catch {
            showModal({
                title: t('payments.hooks.qr.modalTitle'),
                text: t('payments.hooks.qr.errorValue'),
            });

            setIsLoadingCard(false);
            setIsLoadingQR(false);
        }
    };

    return {
        SBPPay,
        isLoadingCard,
        isLoadingQR,
    };
};
