import { ERoutes } from '~/processes/routes';
import { $api } from '~/shared/api';
import { removedHashLink } from './utils';

interface IGetPaymentGeneral {
    error: number;
    Msg: string;
    data: {
        PaymentId: string;
    PaymentURL: string;
    }
}

export const paymentGeneral = async (session: string, amount: number) => {

    const { data } = await $api.post<IGetPaymentGeneral>('/LinkOrder/LinkOrderApi.php', {
        session,
        amount,
        successURL: `${removedHashLink(window.location.href)}${ERoutes.PAYMENT_SUCCESS}`,
        FailURL: `${removedHashLink(window.location.href)}${ERoutes.PAYMENT_ERROR}`,
    });

    return data;
};
