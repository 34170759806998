import { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import App from '~/app/App';
import { STORE_BUILDER } from '~/shared/store/_store.builder';

/** Инициализация контекста с хранилищем Mobx */
export const MobxContext = createContext(STORE_BUILDER);

const root = ReactDOM.createRoot(
    (document.getElementById('root') || document.createElement('div')) as HTMLElement
);

root.render(
    <MobxContext.Provider value={STORE_BUILDER}>
        <App />
    </MobxContext.Provider>
);
