import type { ModalPropsType } from '~/shared/entity';
import { SHOW_MODAL, HIDE_MODAL } from './modalTypes';

const handlers = {
    [SHOW_MODAL]: (state: stateType, action: actionsType) => ({ show: true, ...action.payload }),
    [HIDE_MODAL]: () => ({ show: false }),
    DEFAULT: (state: stateType) => state,
};

type actionHideModalType= {
    type: typeof HIDE_MODAL;
    payload?: object;
};

type actionShowModalType= {
    type: typeof SHOW_MODAL;
    payload: ModalPropsType
};

type actionsType = actionHideModalType | actionShowModalType;

type stateType = {
    show: boolean;
    title?: string;
    text?: string;
    buttons?: React.ReactNode
    content?: React.ReactNode;
    isSmall?: boolean;
};

export const modalReducer = (state: stateType, action: actionsType) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
};
