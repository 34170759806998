import type { PropsWithChildren } from 'react';
import classes from './section.module.scss';

interface ISectionProps extends PropsWithChildren {
    title?: string;
    withContainer?: boolean;
    zeroPadding?: boolean;
    smallPadding?: boolean;
    icon?: string;
    handleClose?: () => void;
}

const Section = ({
    children,
    title,
    withContainer = true,
    zeroPadding = false,
    smallPadding = false,
    icon,
    handleClose,
}: ISectionProps) => (
    <section className={`${classes.box} ${zeroPadding ? classes.zeroPadding : ''} ${smallPadding ? classes.smallPadding : ''}`}>
        <div className={`${withContainer ? 'container' : ''}`}>
            {title && (
                <h2 className={`${classes.title} ${handleClose && classes.title_with_close}`}>
                    { icon && <img src={icon} alt='icon' /> }
                    {title}
                </h2>
            )}
            { handleClose && (
                <button
                    className={classes.close}
                    onClick={handleClose}
                />
            )}
            {children}
        </div>
       
    </section>
);

export default Section;
