import { useState, useEffect, useRef } from 'react';
import classes from './menu.module.scss';
// import MenuIcon from '@icons/MenuIcon';
// import { useNavigate } from 'react-router-dom';
// import { ERoutes } from '~/processes/routes';

const Menu = () => {
    const [visible, setVisible] = useState<boolean>(false);
    const menuRef = useRef<HTMLInputElement>(null);
    // const navigate = useNavigate();

    const handleKeydown = (e: KeyboardEvent): void => {
        if (e.key === 'Escape') {
            setVisible(false);
        }
    };

    const handleClick = (e: MouseEvent): void => {
        if (menuRef.current && !menuRef.current.contains((e as any).target)) {
            setVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeydown);
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('keydown', handleKeydown);
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);

    // const onClickToItem = (path: ERoutes) => {
    //     navigate(path);
    //     setVisible(false);
    // };

    // const openClass = visible ? classes.isOpen : '';

    return (
        <>
            <div className={`${classes.box}`} ref={menuRef}>
                {/* <button
                    onClick={() => setVisible(!visible)}
                    className={`${classes.button} button-reset`}
                >
                    <MenuIcon />
                </button>
                <nav className={`${classes.nav} ${openClass}`}>
                    <ul>
                        <li>
                            <a onClick={() => onClickToItem(ERoutes.BONUS)}>Пригласи друга</a>
                        </li>
                        <li>
                            <a href="#">Политика конфиденциальности</a>
                        </li>
                        <li>
                            <a href="#">Пользовательское соглашение</a>
                        </li>
                    </ul>
                </nav> */}
            </div>
            {visible && <div className={classes.overlay} />}
        </>
    );
};

export default Menu;
