import { useMemo, useRef } from 'react';

interface IConfig {
    maxWidth: number;
}

export const useSlideWidth = ({ maxWidth }: IConfig) => {
    const sliderListRef = useRef(null);

    const slideWidth = useMemo(() => {
        if (sliderListRef.current) {
            const elementWidth = (sliderListRef.current as HTMLDivElement).getBoundingClientRect().width;

            if (elementWidth < maxWidth) {
                return elementWidth;
            }
        }

        return maxWidth;
    }, [sliderListRef.current]);

    return {
        slideWidth,
        sliderListRef,
    };
};
