import Section from '@components/Section/Section';
import PaymentLoader from '@components/Payment/PaymentLoader';

const PaymentLoaderPage = () => (
    <Section>
        <PaymentLoader />
    </Section>
);

export default PaymentLoaderPage;
