import Section from '@components/Section/Section';
import PaymentSuccess from '@components/Payment/PaymentSuccess';

const PaymentSuccessPage = () => (
    <Section>
        <PaymentSuccess />
    </Section>
);

export default PaymentSuccessPage;
