import type { FC } from 'react';
import classes from './ratingStars.module.scss';
import StarIcon from '@icons/StarIcon';

const ratingPoints = [1, 2, 3, 4, 5];

interface Props {
    rating: number;
    onChange: (value: number) => void;
}

const RatingStars: FC<Props> = ({
    rating,
    onChange
}) => (
    <div className={classes.box}>
        {ratingPoints.map((point) => (
            <div key={point} className={classes.star}>
                <input
                    onChange={({ target }) => onChange(Number(target.value))}
                    type="radio"
                    name="rating"
                    value={point}
                    id={`rating-${point}`}
                />
                <label htmlFor={`rating-${point}`}>
                    <StarIcon isEmpty={point > rating} />
                </label>
            </div>
        ))}
    </div>
);

export default RatingStars;
