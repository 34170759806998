import classes from './bonus.module.scss';
import CopyIcon from '@icons/CopyIcon';
import BackIcon from '@icons/BackIcon';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IBonusProps {
    image: string;
}

const Bonus = ({ image }: IBonusProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation('components');
    return (
        <>
            <div className={classes.back}>
                <div className="container">
                    <a className="cursor-pointer" onClick={() => navigate(-1)}>
                        <BackIcon />
                    </a>
                </div>
            </div>
            <div className="container">
                <div className={classes.box}>
                    <img className={classes.banner} src={image} alt="Бонус" />
                    <div className={classes.buttons}>
                        <button className="button button-fill">
                            {t('bonus.share')}
                        </button>
                        <button className="button-link">
                            {t('bonus.copyLink')} <CopyIcon />
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Bonus;
