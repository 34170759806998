import { ERoutes } from '~/processes/routes';
import { $api } from '~/shared/api';
import { removedHashLink } from './utils';

interface IGetPaymentQR {
    error: number;
    PaymentId: string;
    PaymentURL: string;
    QR: string;
    QR_Msg?: string;
}

/**
 * 
 * @param session 
 * @param amount 
 * @returns 
 * @see https://doc.agb.is/develop/link_order#%D0%BE%D0%BF%D0%BB%D0%B0%D1%82%D0%B0_%D1%82%D0%B8%D0%BD%D1%8C%D0%BA%D0%BE%D1%84
 */
export const paymentQR = async (session: string, amount: number, qr = 1) => {
    const { data } = await $api.post<IGetPaymentQR>('/LinkOrder/LinkOrderApi.php', {
        session,
        amount,
        qr,
        successURL: `${removedHashLink(window.location.href)}${ERoutes.PAYMENT_SUCCESS}`,
        FailURL: `${removedHashLink(window.location.href)}${ERoutes.PAYMENT_ERROR}`,
    });
    return data;
};