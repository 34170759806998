import { useYandexHit } from '~/processes/providers/yandexMetrika/hooks/useYandexHit';
import classes from './payment.module.scss';
import errorImage from '@images/payment-error.svg';
import { EYMActions } from '~/processes/providers/yandexMetrika/entity';
import { useTranslation } from 'react-i18next';

const PaymentError = () => {
    const { ymHit } = useYandexHit();
    const { t } = useTranslation('components');

    const handleBackToPay = () => {
        ymHit(EYMActions.BACK_TO_PAY);
    };

    return (
        <div className={classes.errorBox}>
            <h1 className={classes.title}>
                {t('payments.error.title')}
            </h1>
            <div className={`${classes.info} ${classes.error}`}>
                <div className={classes.errorImage}>
                    <img
                        src={errorImage}
                        width={138}
                        alt={errorImage} />
                </div>
                <p>
                    {t('payments.error.subTitle')}
                </p>
                <a
                    onClick={handleBackToPay}
                    href={`${window.location.pathname}${window.location.search}`}
                    className="button button-fill"
                >
                    {t('payments.error.backButton')}
                </a>
            </div>
        </div>
    );
};

export default PaymentError;
