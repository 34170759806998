import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import type { ILinks } from '~/processes/providers/authorization';

const initialLinks: ILinks = {
    bonus_payment: '',
    cancel_order: '',
    confirm_order: '',
    deposit_payment: '',
    qr: '',
    rate_order: '',
    general_pay: '',
    cloud_payment: {
        currency: '',
        doc_num: '',
        dor_id: 0,
        firm_id: '',
        language: '',
        publicId: '',
        CompanyID: -1
    }
};

/** 
 * Класс для работы с ссылками которые приходят по API
 */
export default class CLinks {
    private _links: ILinks = { ...initialLinks };

    constructor() {
        makeAutoObservable(this);
        makePersistable(this, {
            name: 'CLinks',
            // @ts-ignore
            properties: ['_links'],
            storage: sessionStorage,
            expireIn: 86400000,
        }).then();
    }

    /** Получить все ссылки для работы с заказом */
    public get links(): ILinks {
        return this._links;
    }

    /**
     * Установить новые данные о ссылки
     * @param links - ссылки
     */
    public setLinks(links: ILinks): void {
        this._links = links;
    }

    public isUseCloudPayments(): boolean {
        const cloudPayment = this.links.cloud_payment;
        return cloudPayment && cloudPayment.CompanyID !== -1;
    }
}