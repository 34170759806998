import classes from './stock.module.scss';
import type { IPromotions } from '~/processes/providers/authorization';
import { buildApiString } from '~/shared/lib/helpers';

interface IStockProps {
    promotions: IPromotions;
}

const Stock = ({ promotions }: IStockProps) => {
    const date = `с ${promotions.date_begin} по ${promotions.date_end}`;
    const full_detail = promotions.full_detail?.replace(/\n/g, '<br />');
    return (
        <div className={classes.box}>
            <div
                className={classes.image}
                style={{ backgroundImage: `url(${buildApiString(promotions.link_photo)})` }}
            />
            <div className={classes.info}>
                <div className={classes.dates}>
                    {date}
                </div>
                <h2 className={classes.title}>
                    {promotions.short_detail}
                </h2>
                <p className={classes.text} dangerouslySetInnerHTML={{ __html: full_detail }} />
            </div>
        </div>
    );
};

export default Stock;
