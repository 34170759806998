import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { isDevEnv } from '../../../shared/config/env';
import { storageKey } from '~/shared/components/LanguageSelector';

declare module 'i18next' {
    interface CustomTypeOptions {
        returnNull: false;
    }
}

i18n.use(Backend)
    .use(initReactI18next)
    .use(new LanguageDetector(null, { lookupLocalStorage: storageKey }))
    .init({
        ns: 'common',
        load: 'languageOnly',
        debug: isDevEnv,
        backend: {
            loadPath: './locales/{{lng}}/{{ns}}.json',
            requestOptions: {
                cache: 'no-store',
            },
        },
        missingKeyHandler: (language, namespace, key, fallbackValue) => {
            console.log('Missing translation:', language, namespace, key, fallbackValue);
        },
        interpolation: {
            escapeValue: false,
        },
        fallbackLng: 'en',
        returnNull: false,
    });

export default i18n;
