import { useReducer } from 'react';
import { SliderContext, SliderDispatchContext } from './context';

type Action = {
    type: 'CHANGE_SLIDE_VISIBILITY';
    payload: boolean;
} | {
    type: 'CHANGE_SLIDE';
    payload: number;
} | {
    type: 'ADD_LOADED_IMAGE';
};

const sliderReducer = (state: any, action: Action) => {    
    switch (action.type) {
    case 'CHANGE_SLIDE_VISIBILITY':
        return {
            ...state,
            isVisibleChangeSlideButton: action.payload
        };
    case 'CHANGE_SLIDE':
        return {
            ...state,
            activeSlide: action.payload
        };
    case 'ADD_LOADED_IMAGE':
        return {
            ...state,
            imagesLoaded: state.imagesLoaded + 1,
        };
    default:
        return state;
    }
};

interface ISlideState {
    activeSlide: number;
    isVisibleChangeSlideButton: boolean;
    imagesLoaded: number;
}

const initReducerState: ISlideState = {
    activeSlide: 0,
    isVisibleChangeSlideButton: false,
    imagesLoaded: 0,
};

export const SliderProvider = ({ children }: { children: React.ReactNode }) => {
    const [state, dispatch] = useReducer(sliderReducer, initReducerState);
    return (
        <SliderContext.Provider value={state}>
            <SliderDispatchContext.Provider value={dispatch}>
                {children}
            </SliderDispatchContext.Provider>
        </SliderContext.Provider>
    );
};