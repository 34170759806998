export enum EYMActions {
    OPEN_BARCODE_MODAL = 'open_barcode_modal',
    OPEN_RECEIPT_WINDOW = 'open_receipt_window',
    OPEN_DETAILS_OF_ORDER = 'open_details_of_order',
    SCROLL_TO_TOP = 'scroll_to_top',
    BUTTON_BACK_PAGE = 'button_back_page',
    TO_ACCEPT_ORDER_PAGE = 'to_accept_order_page',
    TO_SHOW_ACCEPT_ORDER_MODAL = 'to_show_accept_order_modal',
    TO_SHOW_CANCEL_ORDER_MODAL = 'to_show_cancel_order_modal',
    PAYMENT_DEPOSIT = 'payment_deposit',
    PAYMENT_BONUS = 'payment_bonus',
    PAYMENT_CARD = 'payment_crd',
    PAYMENT_QR_CODE = 'payment_qr_code',
    PAYMENT_GENERAL = 'payment_general',
    BACK_TO_PAY = 'back_to_pay',
    BACK_TO_ORDER = 'back_to_order',
    HEADER_PHONE = 'header_phone',
    HEADER_LOGO = 'header_logo',
    SERVICE_PHOTO_PREVIEW = 'open_service_photo_preview',
    SHOW_RECOMMENDATIONS = 'show_recommendations',
    HIDE_RECOMMENDATIONS = 'hide_recommendations',
}
