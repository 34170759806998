import Feedback from '@components/Feedback/Feedback';
import Recommendations from '@components/Recommendations';
import Stocks from '@components/Stock/Stocks';
import Section from '@components/Section/Section';
import OrderHeader from '@components/Order/OrderHeader';
import OrderInfo from '@components/Order/OrderInfo';
import OrderTotal from '@components/Order/OrderTotal';
import Order from '@components/Order/Order';
import AttentionIcon from '@icons/AttentionIcon';
import { useStore } from '../shared/hooks/useStore';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import titleIcon from '~/assets/icons/thumb-up.svg';
import { useYandexHit } from '~/processes/providers/yandexMetrika/hooks/useYandexHit';
import { EYMActions } from '~/processes/providers/yandexMetrika/entity';

const OrderСanceledPage = () => {
    const { CCompanyInfo, CRecommendations } = useStore();
    const { ymHit } = useYandexHit();
    const { t } = useTranslation('components');
    const { promotions } = CCompanyInfo.companyInfo;
    const isShowPromotions = promotions.length > 0;

    const handleCloseRecommendations = () => {
        CRecommendations.setShowRecommendations(false);
        ymHit(EYMActions.HIDE_RECOMMENDATIONS);
    };

    return (
        <>
            <Section>
                <Order
                    header={<OrderHeader icon={<AttentionIcon />} />}
                    info={<OrderInfo />}
                    total={<OrderTotal />}
                />
            </Section>

            { CRecommendations.showRecommendations
                && CRecommendations.recommendations
                && CRecommendations.recommendations?.recommendations.length
                && (
                    <Section
                        title={ t('components:recommendations.title') as string }
                        icon={titleIcon}
                        handleClose={handleCloseRecommendations}
                    >
                        <Recommendations />
                    </Section>  
                )}

            {isShowPromotions && (
                <Section title="Наши акции">
                    <Stocks />
                </Section>
            )}

            <Section>
                <Feedback />
            </Section>
        </>
    );
};

export default observer(OrderСanceledPage);
