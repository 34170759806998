import { Route, Routes } from 'react-router-dom';
import { ROUTES } from './lib/config';
import { ERoutes } from './entity/index';

const AppRoutes = () => (
    <Routes>
        {ROUTES.map(({
            path,
            element: Element
        }) => (
            <Route
                key={path}
                path={path}
                element={<Element />}
            />
        ))}
    </Routes>
);

export default AppRoutes;
export { ERoutes };