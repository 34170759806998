import { useContext } from 'react';
import { GalleryContext } from '~/context/gallery/galleryContext';
import { Slider } from './Slider';
import { SliderProvider } from './model/providers';
import classes from './GalleryNext.module.scss';
import { CloseButton } from './Close';

export const Gallery = () => {
    const { gallery, hide } = useContext(GalleryContext);

    if (!gallery.show) {
        return null;
    }

    return (
        <SliderProvider>
            <div className={classes.gallery} onClick={hide}>
                <CloseButton className={classes.close} />
                <Slider images={gallery.images} />
            </div>
        </SliderProvider>
    );
};
