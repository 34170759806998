import { useContext, useState } from 'react';
import { ModalContext } from '~/context/modal/modalContext';
import { setOrderStatus } from '../api/setOrderStatus';
import { EApiStatus } from '~/shared/api/entity';
import { EYMActions } from '~/processes/providers/yandexMetrika/entity';
import { useYandexHit } from '~/processes/providers/yandexMetrika/hooks/useYandexHit';
import { useNavigate } from 'react-router-dom';
import { ERoutes } from '~/processes/routes';
import { useStore } from '~/shared/hooks/useStore';
import { useTranslation } from 'react-i18next';

type TAcceptOrder = [() => Promise<void>, boolean, boolean];

/**
 * Хук который подтверждает заказ
 * @param session - id сессии (CLinks.links.confirm_order)
 * @returns [confirmOrder, loading, error]
 */
export const useAcceptOrder = (session: string): TAcceptOrder => {
    const navigate = useNavigate();
    const { CAuthorization, CLinks } = useStore();
    const { ymHit } = useYandexHit();
    const { t } = useTranslation('components');
    const { show, hide } = useContext(ModalContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const { links } = CLinks;

    const confirmOrder = async () => {
        setLoading(true);
        hide();

        try {
            const query = await setOrderStatus(session);

            if (query.error === EApiStatus.OK) {
                await CAuthorization.updateData();

                if ([links.cloud_payment, links.qr, links.general_pay, links.deposit_payment, links.bonus_payment].some(link => link)) {
                    navigate(ERoutes.PAYMENT);
                } else {
                    navigate(ERoutes.CONFIRMED);
                }
            }
            else {
                show({
                    title: t('confirm.modalAccept.title'),
                    text: t('confirm.modalAccept.errorValue'),
                });
                setError(true);
            }
            
            setLoading(false);
        } catch (error) {
            show({
                title: t('confirm.modalAccept.title'),
                text: t('confirm.modalAccept.errorValue'),
            });
            setLoading(false);
            setError(true);
        }
        ymHit(EYMActions.TO_SHOW_ACCEPT_ORDER_MODAL);
    };

    return [confirmOrder, loading, error];
};