import { useReducer } from 'react';
import type { ServiceImagetype } from '~/shared/entity';
import { GalleryContext } from './galleryContext';
import { galleryReducer } from './galleryReducer';
import { HIDE_GALLERY, SHOW_GALLERY } from './galleryTypes';

export const GalleryState = ({ children }: any) => {
    const [state, dispatch] = useReducer(galleryReducer, { show: false });

    const show = (images: Array<ServiceImagetype>): void => {
        if (Array.isArray(images)) {
            dispatch({
                type: SHOW_GALLERY,
                payload: images,
            });
        }
    };

    const hide = (): void =>
        dispatch({
            type: HIDE_GALLERY,
        });

    return (
        <GalleryContext.Provider
            value={{
                show,
                hide,
                gallery: state,
            }}
        >
            {children}
        </GalleryContext.Provider>
    );
};
