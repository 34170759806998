import type { FC, PropsWithChildren } from 'react';
import { useEffect } from 'react';
import { useStore } from '~/shared/hooks/useStore';
import { useOrderInformation } from './hooks/useOrderInformation';
import LoadingCircle from '~/shared/components/loadingCircle';
import ErrorInfo from '~/shared/components/errorInfo';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';
import { ORDER_STATUS_PAGE } from './entity/index';
import { useQueryParams } from '~/shared/hooks/useQueryParams';
import { useRollbar } from '@rollbar/react';
import { ERoutes } from '../../routes';


type Props = PropsWithChildren;

/**
 * Оболочка для проверки верификации заказа, и для обновления данных, если заказ верифицирован
 * @returns 
 */
const Authorization: FC<Props> = observer(({
    children
}) => {
    const { CAuthorization, CLocalization } = useStore();
    const query = useQueryParams();
    const navigate = useNavigate();
    const location = useLocation();
    const hash = (query.hash || CAuthorization.hash);
    const [orderInformation, loading, error, errorText, fullError] = useOrderInformation(hash);

    const rollbar = useRollbar();

    useEffect(() => {
        CAuthorization.checkAuthorization(hash, orderInformation);

        if (orderInformation && Object.keys(query).length !== 0) {
            const waiting_confirm = orderInformation.order.order_info.waiting_confirm;
            const path = ORDER_STATUS_PAGE[waiting_confirm];

            if (window.location.href.includes(ERoutes.PAYMENT_SUCCESS)) {
                navigate(ERoutes.PAYMENT_SUCCESS);
                return;
            }

            if (window.location.href.includes(ERoutes.PAYMENT_ERROR)) {
                navigate(ERoutes.PAYMENT_ERROR);
                return;
            }

            navigate(path);
        }
    }, [hash, orderInformation, window.location.pathname, CLocalization.lang]);

    const isJSONValue = (value: string) => {
        try {
            JSON.parse(value);

            return true;
        } catch {
            return false;
        }
    };

    if (location.pathname === ERoutes.MAIN && Object.keys(query).length === 0) {
        return (
            <>
                {children}
            </>
        );
    }

    if (error) {
        if (String(hash).length > 0) {
            const info = window.atob(String(hash).slice(1));

            const infoMessages = [
                'Сервис сейчас недоступен. Попробуйте открыть позже',
                'Сервер сейчас не доступен. Повторите позже'
            ];

            if (infoMessages.includes(errorText)) {
                rollbar.info(`Error on loading data from API: ${errorText}`, {
                    title: orderInformation?.company_info.title,
                    error: fullError?.error,
                    errorText: errorText,
                    info: isJSONValue(info) ? JSON.parse(info) : '',
                });
            } else {
                rollbar.warn(`Error on loading data from API: ${errorText}`, {
                    title: orderInformation?.company_info.title,
                    error: fullError?.error,
                    errorText: errorText,
                    info: isJSONValue(info) ? JSON.parse(info) : '',
                });
            }
        }

        return (
            <div className='w-full h-[80vh] flex justify-center items-center'>
                <ErrorInfo label={errorText} errorCode={fullError?.error || 2} />
            </div>
        );
    }

    if (loading || !CAuthorization.isLoaded) {
        return (
            <div className='w-full h-[80vh] flex justify-center items-center'>
                <LoadingCircle />
            </div>
        );
    }

    return (
        <>
            {children}
        </>
    );
});

export default Authorization;
export * from './entity/index';