import classes from './services.module.scss';
import Service from '@components/Services/Service';
import TabletService from '@components/Services/TabletService';
import { EYMActions } from '~/processes/providers/yandexMetrika/entity';
import { useYandexHit } from '~/processes/providers/yandexMetrika/hooks/useYandexHit';
import { useStore } from '~/shared/hooks/useStore';
import { useTranslation } from 'react-i18next';
import { STORE_BUILDER } from '../../shared/store/_store.builder';
import { useMemo } from 'react';
import { type IOrderServices } from '~/processes/providers/authorization';
import { EDevice, useDeviceScreen } from '~/shared/hooks/useDeviceScreen';
import MobileService from './MobileService';

interface IProps {
    hasScrollToTopButton?: boolean;
}

const Services = ({ hasScrollToTopButton = true }: IProps) => {
    const { COrder } = useStore();
    const { order_services } = COrder.order;
    const { ymHit } = useYandexHit();
    const { t } = useTranslation('components');

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        ymHit(EYMActions.SCROLL_TO_TOP);
    };

    const withParentServices = useMemo(() => 
        order_services
            .filter((service) => service.parent_id.length > 0)
            .map((service) => service.parent_id)
    , [order_services]);

    const isParentService = (service: IOrderServices) => {
        if (service.price > 0) {
            return false;
        }

        return withParentServices.includes(String(service.dos_id));
    };

    const services = useMemo(() => {
        let colored = false;

        const result = order_services.map((service) => {
            if (service.parent_id.length === 0) {
                colored = !colored;
            }

            return {
                ...service,
                colored,
            };
        });

        return result;
    }, [order_services]);

    const device = useDeviceScreen();

    return (
        <div className={classes.box}>
            <table className={classes.table}>
                { device !== EDevice.MOBILE && (
                    <thead>
                        <tr className={classes.labels}>
                            { device === EDevice.DESKTOP && (
                                <td>
                                    {t('services.tableHeaders.name')}
                                </td>  
                            )}
                            <td>
                                {t('services.tableHeaders.status')}
                            </td>
                            <td>
                                {t('services.tableHeaders.amount')}
                            </td>
                            <td>
                                {t('services.tableHeaders.price')} ({`${STORE_BUILDER.COrder.order.order_info.currency}`})
                            </td>
                            <td>
                                {t('services.tableHeaders.discount')}
                            </td>
                            <td>
                                {t('services.tableHeaders.orderSum')} ({`${STORE_BUILDER.COrder.order.order_info.currency}`})
                            </td>
                            <td />
                        </tr>
                    </thead>
                )}
                <tbody>
                    {services
                        && services.length > 0
                        && services.map((service) => {
                            if (device === EDevice.TABLET) {
                                return (
                                    <TabletService
                                        key={service.dos_id}
                                        service={service}
                                        onlyName={isParentService(service)}
                                    />
                                );
                            }

                            if (device === EDevice.MOBILE) {
                                return (
                                    <MobileService
                                        key={service.dos_id}
                                        service={service}
                                        onlyName={isParentService(service)}
                                    />
                                );
                            }

                            return (
                                <Service
                                    key={service.dos_id}
                                    service={service}
                                    onlyName={isParentService(service)}
                                />
                            );
                        })}
                </tbody>
            </table>
            { hasScrollToTopButton && (
                <div className="text-center">
                    <button className="button button-fill long" onClick={handleScrollToTop}>
                        {t('services.buttonScrollToTop')}
                    </button>
                </div>  
            )}
        </div>
    );
};

export default Services;
