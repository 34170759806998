import { type IOrderServices } from '~/processes/providers/authorization/entity';
import { EServiceStatus, SERVICE_STATUS } from './entity';
import { useTranslation } from 'react-i18next';
import { formatNumberPrice } from '~/shared/lib/helpers';
import classes from './services.module.scss';
import { useContext } from 'react';
import { GalleryContext } from '~/context/gallery/galleryContext';

interface IServiceWithColor extends IOrderServices {
    colored: boolean;
}

interface IProps {
    service: IServiceWithColor;
    onlyName?: boolean;
}

const TabletService = ({ service, onlyName = false }: IProps) => {
    const statusId = (service.status_id || EServiceStatus.NEW);
    const statusData = SERVICE_STATUS[statusId];
    const { t } = useTranslation('components');
    const { show } = useContext(GalleryContext);

    if (onlyName) {
        return (
            <tr 
                className={`${classes.tablet_parent_item} ${service.colored ? classes.colored : ''}`}
            >
                <td
                    colSpan={service.img_links.length ? 5 : 6}
                    style={{
                        paddingRight: `${ service.img_links ? '10%' : '0'}`,
                    }}
                >
                    { service.name }
                </td>
                { service.img_links && (
                    <td className={classes.photoTd}>
                        <button
                            className={classes.photo_button}
                            onClick={() => show(service.img_links)}
                        >
                            {t('services.showPhotoButton')}
                        </button>
                    </td>
                )}
            </tr>
        );
    }

    return (
        <>
            <tr
                className={
                    `${classes.tablet_child_item} ${service.colored ? classes.colored : ''} ${service.parent_id.length ? classes.btt :classes.bt}`
                }
            >
                <td
                    colSpan={6}
                >
                    <span
                        style={{
                            float: 'left',
                            width: service.img_links ? '80%' : '100%',
                        }}
                    >
                        { service.name }
                    </span>
                    { service.img_links && (
                        <button
                            className={classes.photo_button}
                            onClick={() => show(service.img_links)}
                        >
                            { t('services.showPhotoButton') }
                        </button>
                    )}
                </td>    
            </tr>
            <tr className={`${classes.item} ${service.colored ? classes.colored : ''}`}>
                <td className={classes.data}>
                    <span>{t(statusData.title)}</span>
                </td>
                <td className={classes.data}>
                    <span>
                        {service.number_of_units} { service.unitname ?? t('services.tableItems.namePiece') }
                    </span>
                </td>
                <td className={classes.data}>
                    <span>
                        {formatNumberPrice(service.price, '')}
                    </span>
                </td>
                <td className={classes.data}>
                    <span>
                        {service.discount}%
                    </span>
                </td>
                <td className={classes.data}>
                    <span>
                        {formatNumberPrice(service.sum_service, '')}
                    </span>
                </td>
                <td />
            </tr>
        </>
    );
};

export default TabletService;
