import classes from './Recommendations.module.scss';

interface IProps {
    image: any;
    content: string;
}

const RecommendationItem = ({ content, image }: IProps) => (
    <li className={ classes.item }>
        <div className={classes.image_container}>
            <img src={ image } alt={'recommendation'} />
        </div>
        <span className={ classes.content }>{ decodeURIComponent(content) }</span>
    </li>
);

export default RecommendationItem;
