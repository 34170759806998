import { useContext, useState } from 'react';
import { ModalContext } from '~/context/modal/modalContext';
import { setOrderStatus } from '../api/setOrderStatus';
import { EApiStatus } from '~/shared/api/entity';
import { EYMActions } from '~/processes/providers/yandexMetrika/entity';
import { useYandexHit } from '~/processes/providers/yandexMetrika/hooks/useYandexHit';
import { useNavigate } from 'react-router-dom';
import { ERoutes } from '~/processes/routes';
import { useStore } from '~/shared/hooks/useStore';
import { useTranslation } from 'react-i18next';

type TCancelOrder = [() => Promise<void>, boolean, boolean];

/**
 * Хук который отменяет заказ
 * @param session - ID сессии (CLinks.links.cancel_order)
 * @returns [cancelOrder, loading, error]
 */
export const useCancelOrder = (session: string): TCancelOrder => {
    const navigate = useNavigate();
    const { CAuthorization } = useStore();
    const { ymHit } = useYandexHit();
    const { show, hide } = useContext(ModalContext);
    const { t } = useTranslation('components');
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const cancelOrder = async () => {
        setLoading(true);
        hide();

        try {
            const query = await setOrderStatus(session);

            if (query.error === EApiStatus.OK) {
                await CAuthorization.updateData();
                navigate(ERoutes.CANCELED);
            }
            else {
                show({
                    title: t('confirm.modalCancel.title'),
                    text: query.Msg,
                });
                setError(true);
            }

            setLoading(false);
        } catch (error) {
            show({
                title: t('confirm.modalCancel.title'),
                text: t('confirm.modalCancel.errorValue'),
            });
            setLoading(false);
            setError(true);
        }
        ymHit(EYMActions.TO_SHOW_CANCEL_ORDER_MODAL);
    };

    return [cancelOrder, loading, error];
};