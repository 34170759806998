import { $api } from '~/shared/api';

interface IGetSendFeedback {
    error: number, 
    fl: number; //признак, что сообщение было создано как новая переписка (0),
    id: string; // "10040" //id на чат
    message?: string;
}

/**
 * Отправление отзыв о качестве выполнения работы
 * @param session - используем значение из links.rate_order
 * @param rate - оценка заказа (от 1 до 5)
 * @param comment - комментарий к заказу
 * @see https://doc.agb.is/develop/link_order#%D0%BE%D1%82%D0%BF%D1%80%D0%B0%D0%B2%D0%B8%D1%82%D1%8C_%D1%81%D0%BE%D0%BE%D0%B1%D1%89%D0%B5%D0%BD%D0%B8%D0%B5
 */
export const sendFeedback = async(session: string, rate: number, comment: string) => {
    const { data } = await $api.post<IGetSendFeedback>('/LinkOrder/LinkOrderApi.php', {
        session,
        rate,
        comment
    });
    return data;
};