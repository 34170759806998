import { $api } from '~/shared/api';

export interface IPostPaymentBonus {
    error: number;
    Msg: string;
}

/**
 * Оплата бонусами
 * @param session - ID сессии
 * @param amount - количество используемых бонусов
 * @returns 
 * @see https://doc.agb.is/develop/link_order#%D0%BE%D0%BF%D0%BB%D0%B0%D1%82%D0%B0_%D0%B1%D0%BE%D0%BD%D1%83%D1%81%D0%B0%D0%BC%D0%B8
 */
export const paymentBonus = async (session: string, amount: number) => {
    const { data } = await $api.post<IPostPaymentBonus>('/LinkOrder/LinkOrderApi.php', {
        session,
        amount
    });
    return data;
};