import ButtonBack from '@components/ButtonBack/ButtonBack';
import Section from '@components/Section/Section';
import Order from '@components/Order/Order';
import OrderInfo from '@components/Order/OrderInfo';
import OrderHeader from '@components/Order/OrderHeader';
import OrderTotalTile from '@components/Order/OrderTotalTile';
import Services from '@components/Services/Services';
import { observer } from 'mobx-react';
import { useStore } from '~/shared/hooks/useStore';

const OrderDetailPage = () => {
    const { COrder } = useStore();
    const { order_info } = COrder.order;

    return (
        <>
            <div className="container button-back-container">
                <ButtonBack />
            </div>

            <Section smallPadding={true}>
                <Order
                    header={<OrderHeader />}
                    info={<OrderInfo />}
                />

                <OrderTotalTile
                    sum={order_info.order_sum}
                    serviceCount={order_info.count_of_services}
                />

                <Services />
            </Section>
        </>
    );
};

export default observer(OrderDetailPage);
