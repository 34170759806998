import '@styles/slick-slider.scss';
import Slider from 'react-slick';
import Stock from '@components/Stock/Stock';
import classes from './stock.module.scss';
import { useStore } from '~/shared/hooks/useStore';
import { observer } from 'mobx-react';

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    className: 'stocks-slider',
    responsive: [
        {
            breakpoint: 835,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

const Stocks = () => {
    const { CCompanyInfo } = useStore();
    const { promotions } = CCompanyInfo.companyInfo;
    return (
        <div className={classes.wrapper}>
            <div className={classes.list}>
                <Slider {...settings}>
                    {promotions.map((promotions) => (
                        <Stock key={promotions.promotion_id} promotions={promotions} />
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default observer(Stocks);
