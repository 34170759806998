import classes from './GalleryNext.module.scss';

interface IProps {
    forPreview?: boolean;
}

export const ImageSkeleton = ({ forPreview }: IProps) => {
    if (forPreview) {
        return null;
    }

    return (
        <div className={classes.skeleton}>
            <div />
            <div />
        </div>
    );
};
