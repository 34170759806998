import type { ServiceImagetype } from '~/shared/entity';
import { SHOW_GALLERY, HIDE_GALLERY } from './galleryTypes';

const handlers = {
    [SHOW_GALLERY]: (state: stateType, action: actionsType) => ({ show: true, images: action.payload }),
    [HIDE_GALLERY]: () => ({ show: false }),
    DEFAULT: (state: stateType) => state,
};

type actionHideGalleryType= {
    type: typeof HIDE_GALLERY;
    payload?: object
};

type actionShowGalleryType= {
    type: typeof SHOW_GALLERY;
    payload: Array<ServiceImagetype>
};

type actionsType = actionHideGalleryType | actionShowGalleryType;

type stateType = {
    show: boolean;
    images?: Array<ServiceImagetype>
};

export const galleryReducer = (state: stateType, action: actionsType) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
};
