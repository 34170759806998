import { $api } from '~/shared/api';
import { useStore } from '~/shared/hooks/useStore';

/**
 * Хук для работы с просмотром рекомендаций.
 * @returns setRecommendationActions - функция для установки действия после просмотра рекомендации
 */
export const useRecommendationActions = () => {
    const { CCompanyInfo } = useStore();
    const { companyInfo } = CCompanyInfo;

    const setRecommendationActions = (recommendation_id: number) => {
        const { company_id } = companyInfo;

        const recommendation_type = 1;
        const client_for_api_id = 7;

        const params = {
            client_for_api_id,
            recommendation_id,
            recommendation_type
        };

        $api.get(`/him_general_php/api/?SetRecommendationActions=${JSON.stringify(params)}&CompanyID=${company_id}`);
    };

    return { setRecommendationActions };
};
