import { $api } from '~/shared/api';

/**
 * Отправляет информацию о согласовании заказа для логирования.
 *
 * @param {string} session - сессия.
 * @param {string} agreementData - информация о согласовании.
 * @return {Promise<void>} - Пустой промис.
 */
export const agreementLog = async (session: string, agreementData: string) => {
    await $api.post('/LinkOrder/LinkOrderLog.php', {
        hashed_data: agreementData,
        session,
    });
};
