import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { useStore } from '~/shared/hooks/useStore';
import { langPart } from '../../lib/helpers';

export const storageKey = 'i18SelectLang';
export const userStorageKey = 'i18UserSelectLang';

const LanguageSelector = () => {
    const { CLocalization } = useStore();
    const { lang: selectLang, languages } = CLocalization;
    const { i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleSelectLang = (lang: string, save?: boolean) => {
        localStorage.setItem(userStorageKey, lang);

        i18n.changeLanguage(langPart(lang));
        CLocalization.setLang(langPart(lang));
        setIsOpen(false);
        if (save) {
            window.location.reload();
        }
    };

    useEffect(() => {
        document.documentElement.lang = i18n.language;
    }, [selectLang]);

    return (
        <div className="flex justify-center items-center relative">
            <button className='flex justify-center items-center space-x-2' onClick={() => setIsOpen(prev => !prev)}>
                <span className='uppercase border:bg-slate-300 border-[2px] p-2 rounded-md font-bold w-[50px] h-[40px] flex justify-center items-center hover:bg-slate-200'>
                    {langPart(selectLang) === 'uk' ? 'UA' : langPart(selectLang)}
                </span>
            </button>
            <div className={twMerge('absolute rounded-sm top-12 z-[9999] flex justify-center items-center flex-col w-fit px-1 transition-opacity duration-200', isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none')}>
                {languages.map(lang => {
                    if (lang !== selectLang) {
                        return (
                            <button key={lang} onClick={() => handleSelectLang(lang, true)}>
                                <div className='w-[50px] h-[40px] p-2 mt-[4px] rounded-md font-bold flex justify-center items-center bg-slate-50 border:bg-slate-200 border-[2px] hover:bg-slate-200'>
                                    {lang.toUpperCase()}
                                </div>
                            </button>
                        );
                    }
                })}
            </div>
        </div>
    );
};

export default observer(LanguageSelector);