import Section from '@components/Section/Section';
import PaymentError from '@components/Payment/PaymentError';
import ButtonBack from '@components/ButtonBack/ButtonBack';

const PaymentErrorPage = () => (
    <Section>
        <ButtonBack isBold isSide />
        <PaymentError />
    </Section>
);

export default PaymentErrorPage;
