import OrderNewPage from '~/pages/orderNewPage/OrderNewPage';
import BonusPage from '~/pages/BonusPage';
import ConfirmPage from '~/pages/ConfirmPage';
import OrderConfirmedPage from '~/pages/OrderConfirmedPage';
import OrderСanceledPage from '~/pages/OrderСanceledPage';
import OrderDetailPage from '~/pages/OrderDetailPage';
import PaymentPage from '~/pages/PaymentPage';
import PaymentLoaderPage from '~/pages/PaymentLoaderPage';
import PaymentSuccessPage from '~/pages/PaymentSuccessPage';
import PaymentErrorPage from '~/pages/PaymentErrorPage';
import { ERoutes } from '../entity';
import { TmpMainPage } from '~/pages/TmpMainPage';

/**
 * Объект всех страниц в проекте
 * Тут происходит основная инициализация страницы по маршруту
 */
export const ROUTES = [
    {
        path: ERoutes.MAIN,
        element: TmpMainPage,
    },
    {
        path: ERoutes.NEW,
        element: OrderNewPage
    },
    {
        path: ERoutes.BONUS,
        element: BonusPage
    },
    {
        path: ERoutes.CONFIRM,
        element: ConfirmPage
    },
    {
        path: ERoutes.CONFIRMED,
        element: OrderConfirmedPage
    },
    {
        path: ERoutes.CANCELED,
        element: OrderСanceledPage
    },
    {
        path: ERoutes.DETAIL,
        element: OrderDetailPage
    },
    {
        path: ERoutes.PAYMENT,
        element: PaymentPage
    },
    {
        path: ERoutes.PAYMENT_LOADER,
        element: PaymentLoaderPage
    },
    {
        path: ERoutes.PAYMENT_SUCCESS,
        element: PaymentSuccessPage
    },
    {
        path: ERoutes.PAYMENT_ERROR,
        element: PaymentErrorPage
    },
];