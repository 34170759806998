import LoaderIcon from '@icons/LoaderIcon';
import { useTranslation } from 'react-i18next';
import classes from './payment.module.scss';

const PaymentLoader = () => {
    const { t } = useTranslation('components');
    return (
        <>
            <h1 className={classes.title}>
                {t('payments.loader.title')}
            </h1>
            <div className={classes.loader}>
                <LoaderIcon />
                <p>
                    {t('payments.loader.subTitle')}
                </p>
            </div>
        </>
    );
};

export default PaymentLoader;
