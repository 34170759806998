import { useEffect, useState } from 'react';

export function useIsMobile() {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        setIsMobile(/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    }, [navigator.userAgent]);

    useEffect(() => {
        window.addEventListener('resize', () => {
            setIsMobile(window.innerWidth < 768);
        });

        return () => {
            window.removeEventListener('resize', () => {
                setIsMobile(window.innerWidth < 768);
            });
        };
    }, []);
    
    return isMobile;
}
