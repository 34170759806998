import { type ChangeEvent, useContext, useState } from 'react';
import classes from './confirm.module.scss';
import CheckIcon from '@icons/CheckIcon';
import { ModalContext } from '~/context/modal/modalContext';
import { observer } from 'mobx-react';
import { useStore } from '~/shared/hooks/useStore';
import { buildApiString } from '~/shared/lib/helpers';
import LoadingCircle from '~/shared/components/loadingCircle';
import { useAcceptOrder } from './hooks/useAcceptOrder';
import { useCancelOrder } from './hooks/useCancelOrder';
import { useTranslation } from 'react-i18next';
import Services from '../Services/Services';
import OrderTotalTile from '../Order/OrderTotalTile';
import { useIsMobile } from '~/shared/hooks/useIsMobile';
import { useAgreement } from './hooks/useAgreement';

const Confirm = () => {
    const { COrder, CLinks, CCompanyInfo } = useStore();
    const { order } = COrder;
    const { receipt_link, order_sum, count_of_services } = order.order_info;
    const { show, hide } = useContext(ModalContext);
    const { t } = useTranslation('components');
    const isMobile = useIsMobile();
    const { isConfirm, setIsConfirm } = useAgreement();

    const [isLoadReceipt, setIsLoadRecepiot] = useState<boolean>(false);
    const [isErrorReceipt, setIsErrorReceipt] = useState<boolean>(false);
    const [confirmOrder, loadingAccept] = useAcceptOrder(CLinks.links.confirm_order);
    const [cancelOrder, loadingCancel] = useCancelOrder(CLinks.links.cancel_order);
    const docUrl = buildApiString(`${receipt_link}&mobile=${ isMobile }`);

    const modalProps = {
        title: t('confirm.modal.title'),
        text: t('confirm.modal.text'),
        buttons: (
            <>
                <button className="button button-fill" disabled={loadingCancel} onClick={cancelOrder}>
                    {t('confirm.modal.content.cancelOrder')}
                </button>
                <button className="button button-bordered" disabled={loadingCancel} onClick={hide}>
                    {t('confirm.modal.content.back')}
                </button>
            </>
        ),
    };

    const handleConfirm = async (e: ChangeEvent<HTMLInputElement>) => {
        setIsConfirm({
            ...isConfirm,
            [e.target.id]: e.target.checked,
        });
    };

    return (
        <div className={classes.box}>
            <h1 className={classes.title}>{ t('components:pages.confirmPage.mainTitle') }</h1>
            <p className={classes.text}>
                {t('confirm.title')}
            </p>
            <OrderTotalTile
                sum={order_sum}
                serviceCount={count_of_services}
            />
            <Services hasScrollToTopButton={false} />
            <div className={classes.contract}>
                {!isLoadReceipt &&
                    <LoadingCircle label={t('confirm.receiptLink.loading') as string} />
                }
                <img
                    className="w-full shadow-lg hover:shadow-xl"
                    src={docUrl}
                    height={1000}
                    onLoad={() => setIsLoadRecepiot(true)}
                    onError={() => setIsErrorReceipt(true)}
                />
                {isErrorReceipt &&
                    (
                        <div>
                            {t('confirm.receiptLink.notFoundReceiptLink')}
                        </div>
                    )
                }
            </div>
            <div className={classes.agreement}>
                <div className={classes.checkbox}>
                    <input
                        type="checkbox"
                        id="agreement"
                        disabled={!isLoadReceipt}
                        checked={isConfirm.agreement}
                        onChange={handleConfirm}
                    />
                    <label htmlFor="agreement">
                        <span>
                            <CheckIcon />
                        </span>
                        {t('confirm.acceptDescription')}
                    </label>
                    { !['1'].includes(CCompanyInfo.companyInfo.country_id) ? (
                        <>
                            <input
                                type="checkbox"
                                id="agreement_e_signature"
                                disabled={!isLoadReceipt}
                                checked={isConfirm.agreement_e_signature}
                                onChange={handleConfirm}
                            />
                            <label htmlFor="agreement_e_signature">
                                <span>
                                    <CheckIcon />
                                </span>
                                {signatureConfirmMap.has(CCompanyInfo.companyInfo.country_id) ? (
                                    t(signatureConfirmMap.get(CCompanyInfo.companyInfo.country_id) as string)
                                ) : (
                                    t('confirm.acceptESignatureDescription')
                                )}
                            </label>
                        </>
                        
                    ) : null }
                    
                    { ['4', '2'].includes(CCompanyInfo.companyInfo.country_id) ? (
                        <>
                            <input
                                type="checkbox"
                                id="personal_information"
                                disabled={!isLoadReceipt}
                                checked={isConfirm.personal_information}
                                onChange={handleConfirm} /><label htmlFor="personal_information">
                                <span>
                                    <CheckIcon />
                                </span>
                                { acceptPersonalInformationMap.has(CCompanyInfo.companyInfo.country_id) ? (
                                    t(acceptPersonalInformationMap.get(CCompanyInfo.companyInfo.country_id) as string)
                                ) : (
                                    t('confirm.acceptPersonalInformation')  
                                )}
                            </label>
                        </>
                    ) : null }
                </div>
                <div className={classes.buttons}>
                    <button
                        className="button button-bordered"
                        disabled={loadingCancel}
                        style={{ fontSize: t('confirm.buttonCancel.label').length >= 20 ? 12 : 16 }}
                        onClick={() => show(modalProps)}
                    >
                        {loadingCancel ? t('confirm.buttonCancel.loading') : t('confirm.buttonCancel.label')}
                    </button>
                    <button
                        disabled={
                            !isConfirm.agreement
                            || (!['1'].includes(CCompanyInfo.companyInfo.country_id ) && !isConfirm.agreement_e_signature)
                            || (['4', '2'].includes(CCompanyInfo.companyInfo.country_id) && !isConfirm.personal_information)
                            || !isLoadReceipt
                            || loadingAccept}
                        className={`button button-fill ${classes.buttonConfirm}`}
                        style={{ fontSize: t('confirm.buttonAccept.label').length >= 20 ? 12 : 16 }}
                        onClick={confirmOrder}
                    >
                        {loadingAccept ? t('confirm.buttonAccept.loading') : t('confirm.buttonAccept.label')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default observer(Confirm);

const signatureConfirmMap = new Map([
    ['4', 'confirm.acceptESignatureDescriptionRu'],
    ['5', 'confirm.acceptESignatureDescriptionUa'],
    ['2', 'confirm.acceptESignatureDescriptionKz'],
]);

const acceptPersonalInformationMap = new Map([
    ['4', 'confirm.acceptPersonalInformation'],
    ['2', 'confirm.acceptPersonalInformationKz'],
]);
