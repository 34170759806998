

const LoaderIcon = () => (
    <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M31 5.16699V10.3337" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M43.9168 8.62793L41.3335 13.1024" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M53.3722 18.084L48.8977 20.6673" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M56.8334 31H51.6667" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M53.3722 43.9173L48.8977 41.334" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M43.9168 53.3729L41.3335 48.8984" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M31 56.8337V51.667" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18.0835 53.3729L20.6668 48.8984" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.62793 43.9173L13.1024 41.334" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.16675 31H10.3334" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.62793 18.084L13.1024 20.6673" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18.0835 8.62793L20.6668 13.1024" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default LoaderIcon;