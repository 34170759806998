import { $api } from '~/shared/api';


interface IPostSetOrderStatus {
    error: number,
    Msg: string
}

/**
 * 
 * @param session 
 * @see https://doc.agb.is/develop/link_order#%D0%BF%D0%BE%D0%B4%D1%82%D0%B2%D0%B5%D1%80%D0%B4%D0%B8%D1%82%D1%8C_%D0%B7%D0%B0%D0%BA%D0%B0%D0%B7 (Подтвердить)
 * @see https://doc.agb.is/develop/link_order#%D0%BE%D1%82%D0%BC%D0%B5%D0%BD%D0%B8%D1%82%D1%8C_%D0%B7%D0%B0%D0%BA%D0%B0%D0%B7 (Отменить)
 */
export const setOrderStatus = async (session: string) => {
    const { data } = await $api.post<IPostSetOrderStatus>('/LinkOrder/LinkOrderApi.php', {
        session
    });
    return data;
};