import classes from './order.module.scss';
import { formatNumberPrice } from '~/shared/lib/helpers';
import { useTranslation } from 'react-i18next';

interface IOrderTotalTileProps {
    serviceCount: number;
    sum: number;
}

const OrderTotalTile = ({ serviceCount, sum }: IOrderTotalTileProps) => {
    const { t } = useTranslation('components');
    return (
        <ul className={classes.totalTile}>
            <li>
                <span>
                    {t('order.totalTitle.title')}
                </span>
                <b>{serviceCount}</b>
            </li>
            <li>
                <span>
                    {t('order.totalTitle.subTitle')}
                </span>
                <b>{formatNumberPrice(sum)}</b>
            </li>
        </ul>
    );
};

export default OrderTotalTile;
