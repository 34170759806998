import { $api } from '~/shared/api';
import type { IGetOrderInformation } from '../entity';

/**
 * Получение всей информации по заказу
 * @param hash - Хэш получаемый из параметров строки, который нам передается при редиректе
 * @example
 * const query = getOrderInformation('1eyJDb21wYW55VG9rZW4iOiJCMDZERTczMi0xRjM3LTQ4NUEtQjgzOC1ERkZGRUU5QjJFRDkiLCJTZXNzaW9uSUQiOiJDMjVCM0IwMi1EMDkyLTRFQ0YtQjRDNC1GNUQzMEExRThERjYiLCJkb3JfaWQiOjEwMDQ5MX0')
 * @see https://doc.agb.is/develop/link_order#%D0%BF%D0%BE%D0%BB%D1%83%D1%87%D0%B8%D1%82%D1%8C_%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D1%8E_%D0%BF%D0%BE_%D0%B7%D0%B0%D0%BA%D0%B0%D0%B7%D1%83
 */
export const getOrderInformation = async (hash: string) => {
    const { data } = await $api.get<IGetOrderInformation>(`/LinkOrder/LinkOrderApi.php?type=order&session=${hash}`);
    return data;
};