import { useContext } from 'react';
import { SliderContext, SliderDispatchContext } from './context';

interface IConfig {
    slidesCount: number;
}

export const useChangeSlide = ({ slidesCount }: IConfig) => {
    const dispatch = useContext(SliderDispatchContext);
    const { activeSlide } = useContext(SliderContext);

    const changeSlide = (direction: 'next'|'prev') => {        
        if (direction === 'next') {
            if (activeSlide === slidesCount - 1) {
                dispatch({ type: 'CHANGE_SLIDE', payload: 0 });
            } else {
                dispatch({ type: 'CHANGE_SLIDE', payload: activeSlide + 1 });
            }
        } else if (direction === 'prev') {
            if (activeSlide === 0) {
                dispatch({ type: 'CHANGE_SLIDE', payload: slidesCount - 1 });
            } else {
                dispatch({ type: 'CHANGE_SLIDE', payload: activeSlide - 1 });
            }
        }
    };

    return {
        changeSlide,
    };
};
