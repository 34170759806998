import { useEffect, useState } from 'react';
import { EApiStatus } from '~/shared/api/entity';
import { getOrderInformation } from '../api/getOrderInformation';
import type { IGetOrderInformation, TFullError, TOrderInformation } from '../entity';

/**
 * Получаем все данные от сервера в одном JSON
 * @param hash - хеш, который отправляется на сервер, для возврата данных (Узнавать у бэк-разработчика)
 * @example ?hash=3eyJDb21wYW55VG9rZW4iOiIwREYyNTA0OS0xMzk0LTQ5MzMtOEE3MC02NjE2OEZGOTIwMDEiLCJTZXNzaW9uSUQiOiIxMDAwMDAwMC0wMDAwLTAwMDAtMDAwMC0wMDAwMDAwMDAwMDEiLCJkb3JfaWQiOjEwMTJ9
 * @returns [orderInformation, loading, error, errorText]
 */
export const useOrderInformation = (hash: string): TOrderInformation => {
    const [orderInformation, setOrderInformation] = useState<IGetOrderInformation>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>('');
    const [fullError, setFullError] = useState<TFullError | null>(null);

    /**
     * Получение данных по API с проверкой на ошибки, и инициализация при успешной отработке
     */
    const fetchOrderInformation = async () => {
        try {
            /** Запрашиваем данные по API используя хеш страницы */
            const query = await getOrderInformation(hash);

            /** Если статус ошибки не равен 0, тогда отрабатывает ошибка, и выдает catch */
            if (query.error !== EApiStatus.OK) {
                setLoading(false);
                setError(true);
                setFullError({
                    error: query.error,
                    message: query.message
                });

                return setErrorText(query.message);
            }

            /** Инициализируем данные, которые получили по API */
            setOrderInformation(query);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    };

    useEffect(() => {
        fetchOrderInformation().then();
    }, [hash]);

    return [orderInformation, loading, error, errorText, fullError];
};