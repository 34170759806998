import { ERoutes } from '~/processes/routes';

// company info
export interface IPromotions {
    date_begin: string, //дата начала акции
    date_end: string,  //дата конца акции
    full_detail: string;
    link_photo: string;
    name: string, //название акции
    short_detail: string, //краткая информация по акции
    promotion_id: number // айди акции   
}

export interface ICompanyInfo {
    phone: string;
    logo: string;
    company_id: number;
    promotions: IPromotions[],
    website: string;
    title: string;
    email: string;
    need_metrika: number;
    country_id: string;
}

// links

export interface ICloudPayments {
    publicId: string,
    currency: string,
    dor_id: number,
    doc_num: string,
    firm_id: string,
    language: string,
    CompanyID: number
}

export interface ILinks {
    confirm_order: string,
    cancel_order: string
    bonus_payment: string, // для оплаты бонусов
    deposit_payment: string, // для оплаты депозитов
    qr: string; // для оплаты по QR
    rate_order: string;
    cloud_payment: ICloudPayments // для оплаты через cloud_payments (менять ничего не нужно. Только добавить свои поля)
    general_pay: string;
}

interface IOrderServiceImage {
    url: string;
    url_miniature: string;
}

interface ITechnologistComment {
    id: number;
    description: string;
    dttm: string;
}

// Order
export interface IOrderServices {
    dos_id: number, //  ID услуги в заказе
    qty_kredit: string,  // количество(хз как правильно определяется)
    sum_service: number, // сумма услуги
    price: number, // цена услуги без скидки
    discount: number, // скидка по услуге
    name: string, // название услуги
    status_id: number, // статус услуги
    unitname: string, // наименование единицы измерена(шт.)
    serv_num: number, // номер услуги
    barcode_read: string, // бирка услуги
    photo_exist: number // наличие фото к услуге
    number_of_units: number // наличие фото к услуге
    parent_id: string // id рооительской услуги (для обувных услуг)
    img_links: IOrderServiceImage[],
    technologist_comment: ITechnologistComment[],
}

export interface IOrderPayments {
    cash: number;
    bank: number;
    card: number;
    bonus: number;
    deposit: number;
}

export interface IOrderPaymentsSum {
    sum_cash: number,
    sum_bank: number,
    sum_card: number,
    sum_bonus: number,
    sum_deposit: number,
    all_sum: number
}

export enum EDeliveryType {
    PICKUP = 1,
    DELIVERY = 2,
}

export enum EOrderPlanStatus {
    NEW = 0,
    ACCEPTED = 3,
    IN_PROGRESS = 4,
}

//Была серьезная экономия на буквах, поэтому каждое поле требует комментарий 🦖
export interface IOrderPlans {
    id: number;
    tp: EDeliveryType; // тип выезда Забрать \ Доставить 
    dt: string; // дата выезда
    hr: number; // час начала выезда
    mn: number; // минута начала выезда
    to_hr: number; // час планового окончания выезда
    to_mn: number; // минута планового окончания выезда
    address: string;
    sclad_id: number; // ID склада выезда
    mp_status_id: EOrderPlanStatus; // текущий статус выезда Новый \ Принял (но не начал движение) \ В пути
}

export interface IOrderInfo {
    doc_num: string,
    date_out: string,
    status_id: number,
    discount: number,
    sclad_hours: string,
    currency: string,
    waiting_confirm: number,
    is_not_confirmed: number,
    order_sum: number,
    need_to_pay: number,
    date_in: string,
    point_of_delivery_name: string,
    point_of_delivery_adr: string,
    is_on_delivery_point: number,
    dor_id: number,
    count_of_services: number,
    receipt_link: string;
    barcode: number; // баркод
}

export interface IClientInfo {
    contr_id: number, // айди клиента
    bonus: number, //сумма бонусов
    deposit: number, //сумма депозитов
    full_name: string, //ФИО полностью
    phone: string //номер телефона
}

export interface IOrder {
    error: number,
    dor_id: string,  // айди документа
    order_services: IOrderServices[] // перечень услуг,
    order_payments: { // оплаты заказа
        payments: IOrderPayments[];
        payments_sum: IOrderPaymentsSum
    },
    order_comments: [],
    order_plans: IOrderPlans[],
    order_signs: [],
    order_info: IOrderInfo,
}

export interface IRecommendation {
    id: number;
    metric: number;
    name: string;
    recommendation_id: number;
}

export interface IRecommendations {
    method: number;
    recommendations: IRecommendation[];
    type: string;
}

export interface IGetOrderInformation {
    error: number;
    message: string;
    client_info: IClientInfo;
    company_info: ICompanyInfo;
    links: ILinks;
    order: IOrder;
    recommendations: IRecommendations;
}

/** Возвращаемые значение из хука */
export type TOrderInformation = [IGetOrderInformation | undefined, boolean, boolean, string, TFullError | null];

export enum EOrderConfirmStatus {
    NOT_REQUIRES_CONFIRMATION = 0,
    REQUIRES_CONFIRMATION = 1,
    CONFIRM = 2,
    CANCELED = 3
}

type TOrderPageKey = {
    [key: number]: string
};

export type TFullError = {
    error: number,
    message: string;
};

export const ORDER_STATUS_PAGE: TOrderPageKey = {
    [EOrderConfirmStatus.NOT_REQUIRES_CONFIRMATION]: ERoutes.CONFIRMED,
    [EOrderConfirmStatus.REQUIRES_CONFIRMATION]: ERoutes.NEW,
    [EOrderConfirmStatus.CONFIRM]: ERoutes.CONFIRMED,
    [EOrderConfirmStatus.CANCELED]: ERoutes.CANCELED,
};