import type { FC, PropsWithChildren } from 'react';
import { observer } from 'mobx-react';


const I18Provider: FC<PropsWithChildren> = ({
    children
}) => (
    <div>
        {children}
    </div>
);

export default observer(I18Provider);