import { useState, useEffect } from 'react';
import base64 from 'base64-js';
import { useStore } from '~/shared/hooks/useStore';
import { agreementLog } from '../api/agreementLog';

export function useAgreement() {
    const [isConfirm, setIsConfirm] = useState({
        agreement: false,
        agreement_e_signature: false,
        personal_information: false,
    });

    const { CClientInfo, CLinks } = useStore();

    const buildAgreementData = () => (
        { ...isConfirm,
            contr_id: CClientInfo.clientInfo.contr_id,
        }
    );

    useEffect(() => {
        const agreementData = buildAgreementData();
        const agreementDataStr = JSON.stringify(agreementData);
        
        const base64String = base64.fromByteArray(
            new TextEncoder().encode(agreementDataStr)
        );

        agreementLog(CLinks.links.rate_order, base64String);
    }, [isConfirm]);

    return {
        isConfirm,
        setIsConfirm,
    };
}
