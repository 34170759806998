import { HashRouter } from 'react-router-dom';
import { ModalState } from '~/context/modal/ModalState';
import { GalleryState } from '~/context/gallery/GalleryState';
import AppLayout from '~/app/AppLayout';
import AppRoutes from '~/processes/routes/index';
import Modal from '@components/Modal/Modal';
import { Gallery } from '@components/GalleryNext';
import { Provider, ErrorBoundary } from '@rollbar/react';
import 'core-js/stable';
import './styles/common.scss';
import '~/processes/providers/i18/i18n';

const rollbarConfig = {
    accessToken: 'd070b673fe964dea99eca391c5d39a82',
    environment: 'prod',
    captureUncaught: true,
    captureUnhandledRejections: true,
    ignoredMessages: [
        'Script error.',
        'processRandomSelector is not defined',
        'runCustomize is not defined',
        'Unexpected token \'function\'',
    ],
    payload: {
        client: {
            javascript: {
                code_version: '1.0.1',
                source_map_enabled: true
            }
        }
    }
};

const App = () => (
    <Provider config={rollbarConfig}>
        <ErrorBoundary>
            <HashRouter>
                <ModalState>
                    <GalleryState>
                        <AppLayout>
                            <AppRoutes />
                        </AppLayout>
                        <Modal />
                        <Gallery />
                    </GalleryState>
                </ModalState>
            </HashRouter>
        </ErrorBoundary>
    </Provider>
);

export default App;
