import type { ButtonHTMLAttributes } from 'react';
import classes from './ShowAllButton.module.scss';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    label: string
}

export const ShowAllButton = ({ label, ...props }: IProps) => (
    <button className={classes.button} {...props}>
        { label }
    </button>
);
