import type { FC } from 'react';
import errorImage from '@images/payment-error.svg';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '~/shared/components/LanguageSelector';
import useIPAndLink from '~/shared/hooks/useIPAndLink';


interface Props {
    label?: string;
    errorCode: number;
}

const ErrorInfo: FC<Props> = (props) => {
    const { t } = useTranslation('common');
    const { showButton, buttonLink } = useIPAndLink(['62.141.124.39', '148.251.44.197', '157.90.89.177']);

    let clientErrorText = '';
    
    switch (props.errorCode) {
    case 6:
        clientErrorText = 'errorInfo.tooFast';        
        break;
    default:
        clientErrorText = 'errorInfo.defaultLabel';
        break;
    }

    return (
        <div className='text-black flex justify-center items-center text-sm flex-col lg:text-xl text-center py-5 font-semibold space-y-5 h-screen lg:h-[70vh]'>
            <p>
                {  t(clientErrorText) }
            </p>
            <img
                className='mb-10'
                src={errorImage}
                width={138}
                alt="errorImage"
            />            
            <LanguageSelector />
            {showButton && (
                <a href={buttonLink} className="border-b">
                    {t('errorInfo.button')}
                </a>
            )}
        </div>
    );
};

export default ErrorInfo;