import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { STORE_BUILDER } from '~/shared/store/_store.builder';
import type { IGetOrderInformation } from '../entity';
import { getOrderInformation } from '../api/getOrderInformation';

/**
 * Класс используется для сохранения и обработки данные авторизации
 */
export default class CAuthorization {
    /** Хеш который приходит по короткой ссылке, получаем через query параметры по полю ?hash */
    private _hash: string | null = null;
    private _isLoaded = false;

    constructor() {
        makeAutoObservable(this);
        /** Сохраняет в sessionStorage все изменяемые поля */
        makePersistable(this, {
            name: 'CAuthorization',
            // @ts-ignore
            properties: ['_hash'],
            storage: sessionStorage,
            expireIn: 86400000,
        });
    }

    /**
     * Получение хэша, для отправки на сервер, что бы получить JSON с данными
     */
    public get hash(): string | null {
        return this._hash;
    }

    /**
     * Установить новый хеш страницы
     * @param hash - Хеш получаемый из query параметра URI по полю ?hash
     */
    public setHash(hash: string): void {
        this._hash = hash;
    }

    /**
     * 
     */

    public get isLoaded(): boolean {
        return this._isLoaded;
    }

    public setIsLoaded(flag: boolean): void {
        this._isLoaded = flag;
    }

    /**
     * Проверка валидности данных, и обновление их внутри хранилища
     * @param hash - хеш страницы
     * @param data - данные получаемые из API из хука useOrderInformation
     */
    public checkAuthorization(hash: string, data: IGetOrderInformation | undefined): void {
        if (data) {
            this.setHash(hash);
            STORE_BUILDER.CCompanyInfo.setCompanyInfo(data.company_info);
            STORE_BUILDER.CLinks.setLinks(data.links);
            STORE_BUILDER.COrder.setOrder(data.order);
            STORE_BUILDER.CClientInfo.setClientInfo(data.client_info);
            STORE_BUILDER.CRecommendations.setRecommendations(data.recommendations ?? null);
            this.setIsLoaded(true);
        }
    }

    /**
     * Обновление информации на новую (во всех компонентах)
     * @returns 
     */
    public async updateData(): Promise<boolean> {
        const hash = this.hash;

        /** Если хеш отсутствует - то обновления не будет, возвращаем ошибку */
        if (!hash) return false;

        try {
            const data = await getOrderInformation(hash);

            /** Если получили информацию, то обновляем её */
            if (data) {
                this.checkAuthorization(hash, data);
                return true;
            }

            return false;
        } catch (error) {
            return false;
        }
    }
}