import ym from '@appigram/react-yandex-metrika';
import ReactGA from 'react-ga4';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useStore } from '~/shared/hooks/useStore';

/**
 * Хук для отслеживания активности на сайте, с использования яндекс метрики
 * @returns 
 */
export const useYandexHit = () => {
    const { CCompanyInfo, CLocalization } = useStore();
    const { company_id, need_metrika, title } = CCompanyInfo.companyInfo;
    const location = useLocation();

    /**
     * @param actionName - действие которое было выполнено
     */
    const ymHit = useCallback((actionName: string) => {
        if ((need_metrika === 1) && (company_id !== -1)) {
            ym('hit', `${company_id}${location.pathname}/${actionName}`);
        }
        //force ga4 send even with disabled metrika
        ReactGA.send({
            hitType: 'pageview',
            page: `${company_id}${location.pathname}/${actionName}`,
            company_id: `${company_id}`,
            order_type: `${location.pathname}`,
            company_title: `${title}`,
            lang: CLocalization.lang,
            title: `${actionName}`
        });
    }, [company_id, location.pathname]);

    return { ymHit };
};