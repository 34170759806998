import { useEffect, useState } from 'react';

export enum EDevice {
    MOBILE = 'mobile',
    TABLET = 'tablet',
    DESKTOP = 'desktop',
}

export function useDeviceScreen() {
    const [device, setDevice] = useState<EDevice>();

    const handleResize = () => {
        if (window.innerWidth < 600) {
            setDevice(EDevice.MOBILE);
        } else if (window.innerWidth < 835) {
            setDevice(EDevice.TABLET);
        } else {
            setDevice(EDevice.DESKTOP);
        }
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return device;
}
