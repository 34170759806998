import { makeAutoObservable } from 'mobx';
import type { ICompanyInfo } from '~/processes/providers/authorization';

/** Начальные значения для информации о компании */
export const initialCompanyInfo: ICompanyInfo = {
    logo: '',
    phone: '',
    company_id: -1,
    promotions: [],
    title: '',
    website: '',
    email: '',
    need_metrika: 0,
    country_id: '4',
};

/** 
 * Класс для работы с информацией о компании
 */
export default class CCompanyInfo {
    private _companyInfo: ICompanyInfo = { ...initialCompanyInfo };

    constructor() {
        makeAutoObservable(this);
    }

    /** Получаем объект с информацией о компании */
    public get companyInfo(): ICompanyInfo {
        return this._companyInfo;
    }

    /**
     * Инициализируем объект с информацией о компании полученный по API.
     * @param companyInfo - объект с информацией о компании
     */
    public setCompanyInfo(companyInfo: ICompanyInfo): void {
        this._companyInfo = companyInfo;
    }
}