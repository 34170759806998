import { useState } from 'react';
import { EYMActions } from '~/processes/providers/yandexMetrika/entity';
import { useYandexHit } from '~/processes/providers/yandexMetrika/hooks/useYandexHit';
import type { ICloudPaymentData } from '../api/paymentCard';
import { cloudPayment } from '../api/paymentCard';
import { ERoutes } from '~/processes/routes';
import { useNavigate } from 'react-router-dom';

type TQRPayment = [() => Promise<void>, boolean, boolean];

export const useCardPayment = (contr_id: number, cloudPaymentData: ICloudPaymentData, amount: number): TQRPayment => {
    const navigate = useNavigate();
    const { ymHit } = useYandexHit();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const cardPayment = async () => {
        setLoading(true);

        const callbacks = {
            onComplete(paymentResult: any ) {
                navigate(paymentResult.sucess ? ERoutes.PAYMENT_SUCCESS : ERoutes.PAYMENT_ERROR);
                setLoading(false);
            },
            onFail( ) {
                navigate(ERoutes.PAYMENT_ERROR);
                setError(true);
                setLoading(false);
            },
            onSuccess() {
                navigate(ERoutes.PAYMENT_SUCCESS);
                setError(true);
                setLoading(false);
            },
        };

        cloudPayment(contr_id, {
            ...cloudPaymentData,
            amount,
        }, callbacks);

        ymHit(EYMActions.PAYMENT_CARD);
    };
    return [cardPayment, loading, error];
};