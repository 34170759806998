import { twMerge } from 'tailwind-merge';

interface IProps {
    onClick: (href: string) => void;
    href: string;
    text: string;
    classNames?: string;
}
const UiFilledButton = (props: IProps) => {
    const { href, onClick } = props;

    return (
        <button
            className={twMerge('button', props.classNames)}
            onClick={() => onClick(href)}
        >
            {props.text}
        </button>
    );
};

export default UiFilledButton;