import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import type { IClientInfo } from '~/processes/providers/authorization';

const initialClientInfo: IClientInfo = {
    bonus: 0,
    contr_id: -1,
    deposit: 0,
    full_name: '',
    phone: '',
};

/** 
 * Класс для работы с информацией о заказе
 */
export default class CClientInfo {
    private _clientInfo: IClientInfo = { ...initialClientInfo };

    constructor() {
        makeAutoObservable(this);
        makePersistable(this, {
            name: 'CClientInfo',
            // @ts-ignore
            properties: ['_clientInfo'],
            storage: sessionStorage,
            expireIn: 86400000,
        }).then();
    }

    /** Возвращает данные о клиенте */
    public get clientInfo(): IClientInfo {
        return this._clientInfo;
    }

    /**
     * Установить новые данные о клиенте
     * @param clientInfo - данные о клиенте
     */
    public setClientInfo(clientInfo: IClientInfo): void {
        this._clientInfo = clientInfo;
    }
}