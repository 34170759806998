import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';


const useIPAndLink = (desiredIPs: string[]) => {
    const [ip, setIp] = useState<string | null>(null);
    const [showButton, setShowButton] = useState(false);
    const [buttonLink, setButtonLink] = useState('');
    const location = useLocation();

    useEffect(() => {
        fetch('https://ipinfo.io/json')
            .then(response => response.json())
            .then(data => {
                setIp(data.ip);
                if (desiredIPs.includes(data.ip)) {
                    setShowButton(true);
                    const searchParams = new URLSearchParams(window.location.search);
                    const hash = searchParams.get('hash');
                    if (hash) {
                        const session = decodeURIComponent(hash);
                        const currentDate = new Date().toISOString().split('T')[0];
                        const newLink = `/api/LinkOrder/LinkOrderApi.php?type=log&date=${currentDate}&session=${encodeURIComponent(session)}`;
                        console.log('New link:', newLink);
                        setButtonLink(newLink);
                    } else {
                        console.log('Hash not found in URL');
                    }
                }
            })
            .catch(error => {
                console.error('Error fetching IP address:', error);
            });
    }, [location.search, desiredIPs]);

    return { ip, showButton, buttonLink };
};

export default useIPAndLink;