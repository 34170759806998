/**
 * Маршруты страниц
 */
export enum ERoutes {
    MAIN = '/',
    NEW = '/new',
    BONUS = '/bonus',
    CONFIRM = '/confirm',
    CONFIRMED = '/confirmed',
    CANCELED = '/canceled',
    DETAIL = '/detail',
    PAYMENT = '/payment',
    PAYMENT_QR = '/payment/qr',
    PAYMENT_LOADER = '/payment/loader',
    PAYMENT_SUCCESS = '/payment/success',
    PAYMENT_ERROR = '/payment/error'
}